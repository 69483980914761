import React from 'react';
import {Textbox, Select} from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../../Config';
import constant from '../../common/constant';
import { FormGroup, TagInput, Button, Label, Toaster, Intent, Position} from '@blueprintjs/core';
import {DateRangeInput} from '@blueprintjs/datetime';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import LocationSearchInput from '../../common/selectLocation';
import torch from '../../../../img/torchad-logo.svg';
import {Auth,ssoInstance} from "gg-react-utilities";

import moment from 'moment';

export default class DigitalForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn: false,
            userId:"",
            cname:"",
            email:"",
            mobile:"",
            caddress:null,
            csite:"",
            camName:"",
            url:"",
            startDate:new Date(),
            endDate:null,
            creative:"",
            size:"",
            distValue:5,
            tags:[],
            lat:[],
            lng:[],
            total:"",
            daily:"",
            viewValue:5000000,
            validate:false,
            hasCnameError:true,
            hasEmailError:true,
            hasMobileError:true,
            hasCsiteError:true,
            hasCamNameError:true,
            hasUrlError:true,
            hasCreativeError:true,
            hasSizeError:true,
            hasTypeError:true,
            locationErrorMsg:null,
            dateErrorMsg:null,
            tagsErrorMsg:null,
            hasTotalError:true,
            hasDailyError:true,
            loader:false
        }
        this.onSubmit= this.onSubmit.bind(this);
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn:res},()=>{
                    if(this.state.isLoggedIn!==true){
                        Auth.login(true);
                    }
                })
            });
        },1)
    }

    onSubmit(event){
        event.preventDefault();
        this.toggleValidating(true);
        if(this.state.caddress===null){
            this.setState({
                locationErrorMsg:"Company Address cannot be empty",
            });
        }else{
            this.setState({
                locationErrorMsg:null
            });
        }
        if(this.state.endDate===null || this.state.startDate===null){
            this.setState({
                dateErrorMsg:"Campaign Schedule cannot be empty"
            });
        }else{
            this.setState({
                dateErrorMsg:null
            });
        }
        if(this.state.tags.length === 0){
            this.setState({
                tagsErrorMsg:"Selected Locations cannot be empty"
            });
        }else{
            this.setState({
                tagsErrorMsg:null
            });
        }
        if( !this.state.hasCnameError &&
            !this.state.hasEmailError &&
            !this.state.hasMobileError &&
            !this.state.hasCsiteError &&
            !this.state.hasCamNameError &&
            !this.state.hasUrlError &&
            !this.state.hasCreativeError &&
            !this.state.hasSizeError &&
            !this.state.hasTotalError &&
            !this.state.hasDailyError &&
            (this.state.locationErrorMsg===null &&
            this.state.dateErrorMsg===null &&
            this.state.tagsErrorMsg === null)){
                this.setState({
                    loader:true
                });
                if (this.state.isLoggedIn) {
                    ssoInstance.post(config.API_URL + "digital-advertisement", {
                        userId:Auth.user().user_id,
                        cname: this.state.cname,
                        userName:Auth.user().username,
                        mobile: this.state.mobile,
                        email: this.state.email,
                        location: this.state.caddress,
                        campaign_site: this.state.csite,
                        campaign_name: this.state.camName,
                        sdate: this.state.startDate,
                        edate: this.state.endDate,
                        url: this.state.url,
                        dist_range: this.state.distValue,
                        target: this.state.tags,
                        lat: this.state.lat,
                        lng: this.state.lng,
                        creatives: this.state.creative,
                        creative_size: this.state.size,
                        total: this.state.total,
                        daily: this.state.daily,
                        range: this.state.viewValue
                    }).then((response) => {
                        const sources = response.data;
                        if( sources.status === true ){
                            window.location.href = config.APP_URL + "success";

                        } else {
                            Toaster.create({position: Position.TOP_RIGHT}).show({
                                message: response.data.message,
                                intent: Intent.DANGER
                            });
                            this.setState({
                                loader:false
                            });
                        }
                    }).catch((error) => {
                        if(error){
                            const sources = error.response.data;
                            console.log(sources);
                            if(sources.error){
                                Object.keys(sources.error).forEach((key)=>{

                                    error.response.data.error[key].forEach((error)=>{

                                        Toaster.create({
                                            position: Position.TOP,
                                        }).show({message: error, intent:Intent.DANGER});

                                    });

                                });

                            }else{
                                if(sources.status===false){

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: sources.message, intent:Intent.DANGER});

                                }else{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: sources.message, intent:Intent.DANGER});
                                }
                            }
                            // dispatch(actions.paymentCatchResponse(sources));
                        }
                        this.setState({
                            loader:false
                        });
                    });
                }else{
                    this.setState({
                        cname:"",
                        email:"",
                        mobile:"",
                        caddress:null,
                        csite:"",
                        camName:"",
                        url:"",
                        startDate:new Date(),
                        endDate:null,
                        creative:"",
                        size:"",
                        distValue:5,
                        tags:[],
                        lat:[],
                        lng:[],
                        total:"",
                        daily:"",
                        viewValue:5000000,
                        validate:false,
                        hasCnameError:true,
                        hasEmailError:true,
                        hasMobileError:true,
                        hasCsiteError:true,
                        hasCamNameError:true,
                        hasUrlError:true,
                        hasCreativeError:true,
                        hasSizeError:true,
                        hasTypeError:true,
                        locationErrorMsg:null,
                        dateErrorMsg:null,
                        tagsErrorMsg:null,
                        hasTotalError:true,
                        hasDailyError:true,
                        loader:false
                    });
                    Auth.login(true);
                }
        }else{

        }
    }
    render(){
        var currentDate = moment(new Date());
        var futureMonth = moment(currentDate).add(6, 'M');
        return(
            <React.Fragment>

                        <form onSubmit={this.onSubmit} className="form-horizontal px-3 pb-5"
                              ref={(el) => this.myFormRef = el}>
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="1"
                                    id={"cname"}
                                    placeholder="Company Name"
                                    value={this.state.cname}
                                    onBlur={e => {
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onChange={(cname, e) => {
                                        this.setState({cname});
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasCnameError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: 'Company Name',
                                        check: true,
                                        required: true
                                    }}/>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="1"
                                            id={"phone"}
                                            name="phone"
                                            type="text"
                                            value={this.state.mobile}
                                            placeholder="Mobile Number"
                                            onChange={(mobile) => {
                                                this.setState({mobile});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasMobileError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Mobile Number",
                                                check: true,
                                                required: true,
                                                customFunc: mobile => {
                                                    if (validator.isMobilePhone(mobile, "en-IN")) {
                                                        return true;
                                                    } else {
                                                        return "Is not a valid mobile number.";
                                                    }
                                                }
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="3"
                                            id={"email"}
                                            name="email"
                                            type="text"
                                            value={this.state.email}
                                            placeholder="Email ID"
                                            onChange={(email) => {
                                                this.setState({email});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasEmailError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Email ID",
                                                check: true,
                                                required: true,
                                                customFunc: email => {
                                                    if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                                        return true;
                                                    } else {
                                                        return "Is not a valid email address";
                                                    }
                                                }
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <FormGroup
                                helperText={this.state.locationErrorMsg}>
                                <LocationSearchInput onType={"address"}
                                                     onAddress={(res) => {
                                                         this.setState({caddress: res});
                                                     }}/>
                            </FormGroup>
                            <hr/>

                            <h5 className="AdType mt-5">Company Details</h5>
                            <div className="row mb-1">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="4"
                                            id={"csite"}
                                            name="csite"
                                            type="text"
                                            value={this.state.csite}
                                            placeholder="Campaign Site"
                                            onChange={(csite) => {
                                                this.setState({csite});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasCsiteError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Campaign Site",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="4"
                                            id={"camName"}
                                            name="camName"
                                            type="text"
                                            value={this.state.camName}
                                            placeholder="Campaign Name"
                                            onChange={(camName) => {
                                                this.setState({camName});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasCamNameError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Campaign Name",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <h5 className="AdType mt-3 bp3-text-medium mb-0">Campaign Schedule</h5>

                            <div className="mb-4 mt-s-3">
                                <FormGroup
                                    helperText={this.state.dateErrorMsg}
                                    label="">
                                    <DateRangeInput
                                        formatDate={date => date.toLocaleDateString()}
                                        shortcuts={false}
                                        parseDate={str => new Date(str)}
                                        minDate={new Date()}
                                        maxDate={futureMonth['_d']}
                                        fill={true}
                                        value={[this.state.startDate, this.state.endDate]}
                                        onFocus={() => this.checkAuth()}
                                        onChange={(range) => {
                                            this.setState({startDate: range[0], endDate: range[1]})
                                        }}/>
                                </FormGroup>
                            </div>
                            <div className="mb-4 pt-2">
                                <Textbox
                                    tabIndex="4"
                                    id={"url"}
                                    name="url"
                                    type="text"
                                    value={this.state.url}
                                    placeholder="Landing Page URL"
                                    onChange={(url) => {
                                        this.setState({url});
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasUrlError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Landing Page URL",
                                        check: true,
                                        required: true,
                                        customFunc: url => {
                                            if (validator.isURL(url, {
                                                protocols: ['http', 'https'],
                                                require_tld: true,
                                                require_protocol: false,
                                                require_host: true,
                                                require_valid_protocol: true,
                                                allow_underscores: false,
                                                host_whitelist: false,
                                                host_blacklist: false,
                                                allow_trailing_dot: false,
                                                allow_protocol_relative_urls: false,
                                                disallow_auth: false
                                            })) {
                                                return true;
                                            } else {
                                                return "Is not a valid Landing Page URL.";
                                            }
                                        }
                                    }}/>
                            </div>
                            <div className="mb-4 pt-2">
                                <LocationSearchInput
                                    onDataChange={(res) => {
                                        var joined = this.state.tags.concat(res);
                                        this.setState({tags: joined});
                                    }}
                                    onLat={(lat) => {

                                        var latJoin = this.state.lat.concat(lat);
                                        this.setState({lat: latJoin});
                                    }}
                                    onLng={(lng) => {
                                        var lngJoin = this.state.lng.concat(lng);
                                        this.setState({lng: lngJoin});
                                    }}/>
                            </div>
                            <div className="mb-4 pt-2">
                                <FormGroup
                                    helperText={this.state.tagsErrorMsg}>
                                    <TagInput
                                        onChange={(tags: string[]) => this.setState({tags})}
                                        placeholder="Select Location on above textbox.."
                                        values={this.state.tags}/>
                                    <TagInput className="display-input"
                                              onChange={(lat: string[]) => this.setState({lat})}
                                              values={this.state.lat}/>
                                    <TagInput className="display-input"
                                              onChange={(lng: string[]) => this.setState({lng})}
                                              values={this.state.lng}/>
                                </FormGroup>
                            </div>
                            <FormGroup
                                label="Targeting Distance (in KMs):"
                                labelFor="text-input"
                                className="mt-5">
                                <InputRange
                                    maxValue={10}
                                    onFocus={() => this.checkAuth()}
                                    minValue={2}
                                    value={this.state.distValue}
                                    onChange={distValue => this.setState({distValue})}
                                    onChangeComplete={distValue => console.log(distValue)}/>
                            </FormGroup>
                            <div className="row">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Select
                                            tabIndex="6"
                                            value={this.state.creative}
                                            onChange={(creative) => {
                                                this.setState({creative});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({
                                                    hasCreativeError: res, validate: false
                                                })}
                                            optionList={constant.CREATIVE_LIST}
                                            validationOption={{
                                                name: "Creatives",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Select
                                            tabIndex="7"
                                            value={this.state.size}
                                            onChange={(size) => {
                                                this.setState({size});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({
                                                    hasSizeError: res,
                                                    validate: false
                                                })}
                                            optionList={constant.CREATIVE_SIZE_LIST}
                                            validationOption={{
                                                name: "Creative Size",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="8"
                                            type="number"
                                            value={this.state.total}
                                            placeholder="Total Budget"
                                            onChange={(total) => {
                                                this.setState({total});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasTotalError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Total Budget",
                                                check: true,
                                                required: true,
                                                type: 'number'
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="8"
                                            type="number"
                                            value={this.state.daily}
                                            placeholder="Daily Budget"
                                            onChange={(daily) => {
                                                this.setState({daily});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasDailyError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Daily Budget",
                                                check: true,
                                                required: true,
                                                type: 'number'
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <FormGroup
                                helperText={"Approx Costing Value : " + this.state.viewValue * 2}
                                label="Total AD Views:"
                                labelFor="text-input"
                                intent="primary"
                                className="mt-4">
                                <InputRange
                                    className="input-value"
                                    maxValue={10000000}
                                    minValue={5000}
                                    onFocus={() => this.checkAuth()}
                                    value={this.state.viewValue}
                                    onChange={viewValue => this.setState({viewValue})}
                                    onChangeComplete={value => console.log(value)}/>
                            </FormGroup>
                            {/*<div className="app-bots-checker mt-5">*/}
                            {/*<h3>Are you Human ?</h3>*/}
                            {/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>*/}
                            {/*</div>*/}
                            <div className="form-group text-center app-sponsor">
                                <Label>Powered By <img src={torch} alt=""/></Label>
                            </div>
                            <Button type="submit" text="Submit" intent="primary" className="mt-4"
                                    loading={this.state.loader}/>
                        </form>

            </React.Fragment>
        );
    }
}
