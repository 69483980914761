import React from 'react';
import {Radiobox} from 'react-inputs-validation';
import config from '../../../../Config';
import constant from '../../common/constant';
import {Card, Elevation, FormGroup, Toaster, Position, Intent} from '@blueprintjs/core';
import {Auth,ssoInstance} from "gg-react-utilities";
import LifeInsuranceInquiry from "./LifeInsuranceInquiry";
import HealthInsuranceInquiry from "./HealthInsuranceInquiry";
import MotorInsuranceInquiry from "./MotorInsuranceInquiry";
import NonMotorInsuranceInquiry from "./NonMotorInsuranceInquiry";
import TravelInsuranceInquiry from "./TravelInsuranceInquiry";

export default class Form extends React.Component{
    constructor(props){
        super(props);
        this.state={
			isLoggedIn: false,
        	userId:"",
            name:"",
            email:"",
            mobile:"",
            city:"",
            state:"",
            pincode:"",
            types:'1',
            validate:false,
            hasNameError:true,
			hasEmailError:true,
			hasMobileError:true,
			hasCityError:true,
			hasStateError:true,
			hasPincodeError:true,
            hasTypeError:true,
            loader:false
        }
        this.onSubmit= this.onSubmit.bind(this);
    }
    toggleValidating(validate) {
        this.setState({ validate });
    }

	async checkAuth(){
		setTimeout(()=>{
			Auth.isLoggedInAsync().then((res) => {
				this.setState({isLoggedIn:res},()=>{
					if(res===false){
						Auth.login(true);
					}
				})
			});
		},1)
	}

    onSubmit(event){
        event.preventDefault();
        this.toggleValidating(true);
        if(!this.state.hasNameError &&
			!this.state.hasEmailError &&
			!this.state.hasMobileError &&
			!this.state.hasCityError &&
			!this.state.hasStateError &&
			!this.state.hasPincodeError &&
			!this.state.hasTypeError){
			this.setState({
				loader:true
			});
			if (this.state.isLoggedIn){
				ssoInstance.post(config.API_URL+"insurance", {
					userId:Auth.user().user_id,
          			userName:Auth.user().username,
					name: this.state.name,
					mobile: this.state.mobile,
					email: this.state.email,
					city: this.state.city,
					state: this.state.state,
					pincode: this.state.pincode,
					insurances_type: this.state.types
				}).then((response) => {
					const sources = response.data;
					if( sources.status === true ){
						window.location.href = config.APP_URL + "success";

					} else {
						Toaster.create({position: Position.TOP_RIGHT}).show({
							message: response.data.message,
							intent: Intent.DANGER
						});
						this.setState({
							loader:false
						});
					}
				}).catch((error) => {
					if(error){
						const sources = error.response.data;
						console.log(sources);
						if(sources.error){
							Object.keys(sources.error).forEach((key)=>{

								error.response.data.error[key].forEach((error)=>{

									Toaster.create({
										position: Position.TOP,
									}).show({message: error, intent:Intent.DANGER});

								});

							});

						}else{
							if(sources.status===false){

								Toaster.create({
									position: Position.TOP,
								}).show({message: sources.message, intent:Intent.DANGER});

							}else{

								Toaster.create({
									position: Position.TOP,
								}).show({message: sources.message, intent:Intent.DANGER});
							}
						}
						// dispatch(actions.paymentCatchResponse(sources));
					}
					this.setState({
						loader:false
					});
				});
			} else {
				this.setState({
					name:"",
					email:"",
					mobile:"",
					city:"",
					state:"",
					pincode:"",
					types:"",
					validate:false,
					hasNameError:true,
					hasEmailError:true,
					hasMobileError:true,
					hasCityError:true,
					hasStateError:true,
					hasPincodeError:true,
					hasTypeError:true,
					loader:false
				});
				Auth.login(true)
			}
        }else{

        }
    }

	renderCurrentForm =()=>{
    	let forms=[
    		<LifeInsuranceInquiry />,
    		<HealthInsuranceInquiry />,
    		<MotorInsuranceInquiry />,
    		<NonMotorInsuranceInquiry />,
    		<TravelInsuranceInquiry />
		]
		return forms[+this.state.types-1]
	};

    render(){
    	let curForm=this.renderCurrentForm();
        return(
			<React.Fragment>
						<div className="container app-form-card mb-5">
							<Card interactive={true} elevation={Elevation.THREE}
								  className="pt-4 pb-5 p-s-0 p-sm-4 pb-s-5 pb-sm-5 justify-content-center d-flex">
								<div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 py-4 px-2">
									<FormGroup
										label="Insurances Type:">
										<Radiobox
											tabIndex="7"
											id={"types"}
											name="types"
											disabled={false}
											value={this.state.types}
											onBlur={(e) => {
											}}
											onChange={(types, e) => {
												//this.setState({index:0})
												this.setState({types});
											}}
											onFocus={() => this.checkAuth()}
											validate={this.state.validate}
											validationCallback={res =>
												this.setState({hasTypeError: res, validate: false})
											}
											optionList={constant.INSURANCE_TYPE_LIST}
											customStyleContainer={{
												display: 'flex',
												justifyContent: 'space-between'
											}}
											validationOption={{
												name: 'Insurance Types',
												check: true,
												required: true
											}}/>
									</FormGroup>

									{curForm}

								</div>
							</Card>
						</div>

			</React.Fragment>
        );
    }
}
