import React from 'react';
import {Textbox} from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../../Config';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import {Auth, ssoInstance} from "gg-react-utilities";

export default class MoterInsuranceInquiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            userId: "",
            name: "",
            email: "",
            mobile: "",
            city: "",
            state: "",
            pincode: "",
            reg_number: "",
            year_of_mfg: "",
            reg_date: "",
            location: "",
            rc_book: "",
            last_or_current_policy: "",
            aadhar_card: "",
            pan_card: "",
            types: '1',
            validate: false,
            hasNameError: true,
            hasEmailError: true,
            hasMobileError: true,
            hasCityError: true,
            hasStateError: true,
            hasPincodeError: true,
            hasTypeError: true,
            loader: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    toggleValidating(validate) {
        this.setState({validate});
    }

    onSubmit(event) {
        event.preventDefault();
        this.toggleValidating(true);
        if (!this.state.hasNameError &&
            !this.state.hasEmailError &&
            !this.state.hasMobileError &&
            !this.state.hasCityError &&
            !this.state.hasStateError &&
            !this.state.hasPincodeError &&
            !this.state.hasTypeError) {
            this.setState({
                loader: true
            });
            if (this.state.isLoggedIn) {
                ssoInstance.post(config.API_URL + "insurance", {
                    userId: Auth.user().user_id,
                    userName: Auth.user().username,
                    name: this.state.name,
                    mobile: this.state.mobile,
                    email: this.state.email,
                    city: this.state.city,
                    state: this.state.state,
                    pincode: this.state.pincode,
                    reg_number: this.state.reg_number,
                    reg_date: this.state.reg_date,
                    location: this.state.location,
                    rc_book: this.state.rc_book,
                    last_or_current_policy: this.state.last_or_current_policy,
                    aadhar_card: this.state.aadhar_card,
                    pan_card: this.state.pan_card,
                    insurances_type: this.state.types
                }).then((response) => {
                    const sources = response.data;
                    if( sources.status === true ){
                        window.location.href = config.APP_URL + "success";

                    } else {
                        Toaster.create({position: Position.TOP_RIGHT}).show({
                            message: response.data.message,
                            intent: Intent.DANGER
                        });
                        this.setState({
                            loader:false
                        });
                    }
                }).catch((error) => {
                    if(error){
                        const sources = error.response.data;
                        console.log(sources);
                        if(sources.error){
                            Object.keys(sources.error).forEach((key)=>{

                                error.response.data.error[key].forEach((error)=>{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent:Intent.DANGER});

                                });

                            });

                        }else{
                            if(sources.status===false){

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});

                            }else{

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});
                            }
                        }
                        // dispatch(actions.paymentCatchResponse(sources));
                    }
                    this.setState({
                        loader:false
                    });
                });

            } else {
                this.setState({
                    name: "",
                    email: "",
                    mobile: "",
                    city: "",
                    state: "",
                    pincode: "",
                    reg_number: "",
                    year_of_mfg: "",
                    reg_date: "",
                    location: "",
                    rc_book: "",
                    last_or_current_policy: "",
                    aadhar_card: "",
                    pan_card: "",
                    types: "",
                    validate: false,
                    hasNameError: true,
                    hasEmailError: true,
                    hasMobileError: true,
                    hasCityError: true,
                    hasStateError: true,
                    hasPincodeError: true,
                    hasTypeError: true,
                    loader: false
                });
                Auth.login(true)
            }
        } else {

        }
    }

    render() {
        return (
            <>
                <form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3 pb-5"
                      ref={(el) => this.myFormRef = el}>
                    <div className="row mb-4">
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="1"
                                    id={"Name"}
                                    placeholder="Full Name"
                                    value={this.state.name}
                                    onBlur={e => {
                                    }}
                                    onChange={(name, e) => {
                                        this.setState({name});
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasNameError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: 'Name',
                                        check: true,
                                        required: true
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="1"
                                    id={"phone"}
                                    name="phone"
                                    type="text"
                                    value={this.state.mobile}
                                    placeholder="Mobile Number"
                                    onChange={(mobile) => {
                                        this.setState({mobile});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasMobileError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Mobile Number",
                                        check: true,
                                        required: true,
                                        customFunc: mobile => {
                                            if (validator.isMobilePhone(mobile, "en-IN")) {
                                                return true;
                                            } else {
                                                return "Is not a valid mobile number.";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="3"
                                    id={"email"}
                                    name="email"
                                    type="text"
                                    value={this.state.email}
                                    placeholder="Email ID"
                                    onChange={(email) => {
                                        this.setState({email});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasEmailError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Email ID",
                                        check: true,
                                        required: true,
                                        customFunc: email => {
                                            if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                                return true;
                                            } else {
                                                return "Is not a valid email address";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="4"
                                    id={"city"}
                                    name="city"
                                    type="text"
                                    value={this.state.city}
                                    placeholder="City"
                                    onChange={(city) => {
                                        this.setState({city});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasCityError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "City",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="5"
                                    type="text"
                                    value={this.state.state}
                                    placeholder="State"
                                    onChange={(state) => {
                                        this.setState({state});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasStateError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "State",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="6"
                                    id={"pincode"}
                                    name="pincode"
                                    type="text"
                                    value={this.state.pincode}
                                    placeholder="Pincode"
                                    onChange={(pincode) => {
                                        this.setState({pincode});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasPincodeError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Pincode",
                                        check: true,
                                        required: true,
                                        customFunc: pincode => {
                                            if (validator.isPostalCode(pincode, "IN")) {
                                                return true;
                                            } else {
                                                return "Is not a valid pincode.";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="7"
                                    id={"reg_number"}
                                    name="reg_number"
                                    type="text"
                                    value={this.state.reg_number}
                                    placeholder="Register Number"
                                    onChange={(reg_number) => {
                                        this.setState({reg_number});
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="8"
                                    id={"year_of_mfg"}
                                    name="year_of_mfg"
                                    type="text"
                                    value={this.state.year_of_mfg}
                                    placeholder="Year Of Mfg"
                                    onChange={(year_of_mfg) => {
                                        this.setState({year_of_mfg});
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="9"
                                    id={"reg_date"}
                                    name="reg_date"
                                    type="text"
                                    value={this.state.reg_date}
                                    placeholder="Register Date"
                                    onChange={(reg_date) => {
                                        this.setState({reg_date});
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="10"
                                    id={"location"}
                                    name="location"
                                    type="text"
                                    value={this.state.location}
                                    placeholder="Location"
                                    onChange={(location) => {
                                        this.setState({location});
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <label className="bp3-file-input bp3-fill">
                                    <input type="file" id={"rc_book"} value={this.state.rc_book} name={"rc_book"} onChange={(rc_book) => {
                                        this.setState({rc_book});
                                    }}/>
                                    <span className="bp3-file-upload-input">Choose RC Book</span>
                                </label>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <label className="bp3-file-input bp3-fill">
                                    <input type="file" id={"last_or_current_policy"} value={this.state.last_or_current_policy} name={"last_or_current_policy"}
                                           onChange={(last_or_current_policy) => {
                                        this.setState({last_or_current_policy});
                                    }}/>
                                    <span className="bp3-file-upload-input">Choose Lats OR Current Policy</span>
                                </label>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <label className="bp3-file-input bp3-fill">
                                    <input type="file" id={"aadhar_card"} value={this.state.aadhar_card} name={"aadhar_card"}
                                           onChange={(aadhar_card) => {
                                               this.setState({aadhar_card});
                                           }}/>
                                    <span className="bp3-file-upload-input">Choose Aadhar Card</span>
                                </label>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="11"
                                    id={"pan_card"}
                                    name="pan_card"
                                    type="text"
                                    value={this.state.pan_card}
                                    placeholder="Pan Card"
                                    onChange={(pan_card) => {
                                        this.setState({pan_card});
                                    }}
                                />
                            </div>
                        </div>

                    </div>


                    {/*<div className="app-bots-checker">*/}
                    {/*<h3>Are you Human ?</h3>*/}
                    {/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>    */}
                    {/*</div>*/}
                    <Button type="submit" className="mt-4" text="Submit" intent="primary"
                            loading={this.state.loader}/>
                </form>
            </>

        );
    }
}
