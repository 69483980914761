import React from 'react';
import ReactDOM from 'react-dom';
import {PackageConfigure} from 'gg-react-utilities';
import {packageConfigure} from './Config';
import App from './App';
import {unregister as unregisterServiceWorker} from './registerServiceWorker';

ReactDOM.render(
    <React.Fragment>
        <App/>
        <PackageConfigure {...packageConfigure} />
    </React.Fragment>
    , document.getElementById('root'));

unregisterServiceWorker();