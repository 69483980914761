import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import {Switch} from 'react-router-dom';
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import Home from './components/homepage/home';
import HRInquiry from './components/HRInquiry/hrInquiry';
import ItrInquiry from './components/ITRInquiry/ITRInquiry';
import InsuranceInquiry from './components/Insurance/insuranceInquiry';
import LoanInquiry from './components/Loan/LoanInquiry'
import Advertisement from './components/Advertisement';
import Success from './components/common/success';
import {Notfound, Maintance} from "gg-react-utilities";
import Web from "./components/Web";
import Cloud from "./components/Cloud";
import Payment from "../core/components/Cloud/Payment";

export default class Root extends Component {

    render() {
        return (
            <React.Fragment>
                {process.env.REACT_APP_IS_MAINTENANCE_MODE==='true' ?
                    <Switch>
                        <PrivateRoute path="*" component={Maintance}/>
                    </Switch>
                    :
                    <Switch>
                        <PrivateRoute exact path="/" component={Home}/>
                        <PrivateRoute exact path="/hr-inquiry" component={HRInquiry}/>
                        <PrivateRoute exact path="/it-inquiry" component={Web}/>
                        <PrivateRoute exact path="/insurance-inquiry" component={InsuranceInquiry}/>
                        <PrivateRoute exact path="/itr-inquiry" component={ItrInquiry}/>
                        <PrivateRoute exact path="/loan-inquiry" component={LoanInquiry}/>
                        <PrivateRoute exact path="/advertisement" component={Advertisement}/>
                        <PrivateRoute exact path="/success" component={Success}/>
                        <PrivateRoute exact path="/global-web-guru" component={Web}/>
                        <PrivateRoute exact path="/cloud" component={Cloud}/>
                        <PrivateRoute exact path='/payment/:status' component={Payment}/>
                        <PrivateRoute path="*" component={Notfound}/>
                    </Switch>
                }
            </React.Fragment>
        );
    }
}
