import React, {Component} from 'react';
import Navbar from '../common/navbar';
import Form from './include/form';

export default class Home extends Component{
    constructor(props){
        super(props);
        document.title = "Vehicles Inquiry | Global Garner";
        this.state = {

        }
    }

    componentDidMount() {

    }



    render(){

        return(
            <React.Fragment>
                <div className="header-below px-3">
                    <div className="header-below-content">
                        <h1 className="heading">Vehicles Inquiry</h1>
                        <div className="heading-desc">
                            <p>Want to Buy your dream car or 2 wheeler ?
                            Worried about funds? Throw your worries and buy through us and get 100% cashback/reward.</p>
                        </div>
                    </div>
                </div>
                <Navbar />
                <Form />
            </React.Fragment>

        );
    }
}
