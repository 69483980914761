import React from 'react';
// import Form from './include/form';
import {Select, Textbox} from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../../Config';
import {Button, FormGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {Auth, ssoInstance} from "gg-react-utilities";
import constant from "../../common/constant";
import {DateInput, DateRangeInput} from "@blueprintjs/datetime";
import moment from 'moment';

export default class LifeInsuranceInquiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            userId: "",
            name: "",
            email: "",
            mobile: "",
            city: "",
            state: "",
            pincode: "",
            categoryValue:"",
            vehicleRegisteredName:"",
            purchaseDate:null,
            manufacture:null,
            modelNo:null,
            expiryDate:null,
            types: '3',
            dateExpErrorMsg:null,
            datePrchErrorMsg:null,
            validate: false,
            hasNameError: true,
            hasEmailError: true,
            hasMobileError: true,
            hasCityError: true,
            hasStateError: true,
            hasPincodeError: true,
            hasCategoryError: true,
            hasVehicleRegisteredNameError: true,
            hasPurchaseDateError: true,
            hasManufactureError: true,
            hasModelNoError: true,
            hasExpiryDateError: true,
            hasTypeError: true,
            loader: false
        }
        this.onSubmit= this.onSubmit.bind(this);
    }

    toggleValidating(validate) {
        this.setState({validate});
    }

    onSubmit(event){
        event.preventDefault();
        this.toggleValidating(true);

        if(this.state.expiryDate===null){
            this.setState({
                dateExpErrorMsg:"Exiting policy expiry date cannot be empty"
            });
        }else{
            this.setState({
                dateExpErrorMsg:null
            });
        }
        if(this.state.purchaseDate===null){
            this.setState({
                datePrchErrorMsg:"Date of purchase cannot be empty"
            });
        }else{
            this.setState({
                datePrchErrorMsg:null
            });
        }
        if(!this.state.hasNameError &&
            !this.state.hasEmailError &&
            !this.state.hasMobileError &&
            !this.state.hasCityError &&
            !this.state.hasStateError &&
            !this.state.hasPincodeError &&
            !this.state.hasCategoryError &&
            !this.state.hasVehicleRegisteredNameError &&
            !this.state.hasManufactureError &&
            this.state.dateExpErrorMsg===null &&
            this.state.datePrchErrorMsg===null &&
            !this.state.hasModelNoError){
            this.setState({
                loader:true
            });
            if (this.state.isLoggedIn){
                ssoInstance.post(config.API_URL+"insurance", {
                    userId:Auth.user().user_id,
                    userName:Auth.user().username,
                    name: this.state.name,
                    mobile: this.state.mobile,
                    email: this.state.email,
                    city: this.state.city,
                    state: this.state.state,
                    pincode: this.state.pincode,
                    general_category:this.state.categoryValue,
                    general_vehicleName:this.state.vehicleRegisteredName,
                    general_dateOfPurchase:moment(this.state.purchaseDate).format("DD/MM/YYYY"),
                    general_manufacture:this.state.manufacture,
                    general_modelNo:this.state.modelNo,
                    general_dateOfExpiry:moment(this.state.expiryDate).format("DD/MM/YYYY"),
                    insurances_type: this.state.types
                }).then((response) => {
                    const sources = response.data;
                    if( sources.status === true ){
                        window.location.href = config.APP_URL + "success";

                    } else {
                        Toaster.create({position: Position.TOP_RIGHT}).show({
                            message: response.data.message,
                            intent: Intent.DANGER
                        });
                        this.setState({
                            loader:false
                        });
                    }
                }).catch((error) => {
                    if(error){
                        const sources = error.response.data;
                        console.log(sources);
                        if(sources.error){
                            Object.keys(sources.error).forEach((key)=>{

                                error.response.data.error[key].forEach((error)=>{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent:Intent.DANGER});

                                });

                            });

                        }else{
                            if(sources.status===false){

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});

                            }else{

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});
                            }
                        }
                        // dispatch(actions.paymentCatchResponse(sources));
                    }
                    this.setState({
                        loader:false
                    });
                });
            } else {
                this.setState({
                    name:"",
                    email:"",
                    mobile:"",
                    city:"",
                    state:"",
                    pincode:"",
                    categoryValue:"",
                    types:'3',
                    dateExpErrorMsg:null,
                    datePrchErrorMsg:null,
                    validate:false,
                    hasNameError:true,
                    hasEmailError:true,
                    hasMobileError:true,
                    hasCityError:true,
                    hasStateError:true,
                    hasPincodeError:true,
                    hasCategoryError:true,
                    hasVehicleRegisteredNameError:true,
                    hasPurchaseDateError:true,
                    hasManufactureError: true,
                    hasModelNoError: true,
                    hasExpiryDateError: true,
                    hasTypeError:true,
                    loader:false
                });
                Auth.login(true)
            }
        }else{

        }
    }

    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn:res},()=>{
                    if(res===false){
                        Auth.login(true);
                    }
                })
            });
        },1)
    }

    render() {
        return (
            <>
                <form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3 pb-5"
                      ref={(el) => this.myFormRef = el}>
                    <div className="row mb-4">
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="1"
                                    id={"Name"}
                                    placeholder="Full Name"
                                    value={this.state.name}
                                    onBlur={e => {
                                    }}
                                    onFocus={() => { this.checkAuth() }}
                                    onChange={(name, e) => {
                                        this.setState({name});
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasNameError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: 'Name',
                                        check: true,
                                        required: true
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="2"
                                    id={"phone"}
                                    name="phone"
                                    type="text"
                                    value={this.state.mobile}
                                    placeholder="Mobile Number"
                                    onFocus={() => this.checkAuth()}
                                    onChange={(mobile) => {
                                        this.setState({mobile});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasMobileError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Mobile Number",
                                        check: true,
                                        required: true,
                                        customFunc: mobile => {
                                            if (validator.isMobilePhone(mobile, "en-IN")) {
                                                return true;
                                            } else {
                                                return "Is not a valid mobile number.";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="3"
                                    id={"email"}
                                    name="email"
                                    type="text"
                                    value={this.state.email}
                                    onFocus={() => this.checkAuth()}
                                    placeholder="Email ID"
                                    onChange={(email) => {
                                        this.setState({email});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasEmailError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Email ID",
                                        check: true,
                                        required: true,
                                        customFunc: email => {
                                            if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                                return true;
                                            } else {
                                                return "Is not a valid email address";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="4"
                                    id={"city"}
                                    name="city"
                                    type="text"
                                    value={this.state.city}
                                    placeholder="City"
                                    onChange={(city) => {
                                        this.setState({city});
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasCityError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "City",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="5"
                                    type="text"
                                    value={this.state.state}
                                    placeholder="State"
                                    onChange={(state) => {
                                        this.setState({state});
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasStateError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "State",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="6"
                                    id={"pincode"}
                                    name="pincode"
                                    type="text"
                                    value={this.state.pincode}
                                    placeholder="Pincode"
                                    onChange={(pincode) => {
                                        this.setState({pincode});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasPincodeError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Pincode",
                                        check: true,
                                        required: true,
                                        customFunc: pincode => {
                                            if (validator.isPostalCode(pincode, "IN")) {
                                                return true;
                                            } else {
                                                return "Is not a valid pincode.";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Select
                                    tabIndex="7"
                                    id={"category"}
                                    name="category"
                                    disabled={false}
                                    value={this.state.categoryValue}
                                    onChange={(categoryValue) => {
                                        this.setState({categoryValue});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasCategoryError: res, validate: false})
                                    }
                                    optionList={constant.INSURANCE_CATEGORY_TYPE_LIST}
                                    validationOption={{
                                        name: 'Category',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Select
                                    tabIndex="8"
                                    id={"vehicleRegisteredName"}
                                    name="vehicleRegisteredName"
                                    disabled={false}
                                    value={this.state.vehicleRegisteredName}
                                    onChange={(vehicleRegisteredName) => {
                                        this.setState({vehicleRegisteredName});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasVehicleRegisteredNameError: res, validate: false})
                                    }
                                    optionList={
                                        [
                                            { id: "", name:  this.state.categoryValue == "" ? "Vehicle is registered in the name of" : this.state.categoryValue + " is registered in the name of"},
                                            { id: "Company", name: "Company" },
                                            { id: "Individual", name: "Individual" }
                                        ]
                                    }
                                    validationOption={{
                                        name: 'VehicleRegisteredName',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <FormGroup
                                    helperText={this.state.datePrchErrorMsg}
                                    label="">
                                    <DateInput
                                        tabIndex="9"
                                        id={"purchaseDate"}
                                        name={"purchaseDate"}
                                        formatDate={date => date.toLocaleString('es-ES', {year: "numeric", month: "2-digit", day: "numeric"})}
                                        parseDate={str => new Date(str)}
                                        placeholder={"Date Of purchase"}
                                        value={this.state.purchaseDate}
                                        validate={this.state.validate}
                                        className={"PurchaseInputDate"}
                                        onBlur={e => {
                                        }}
                                        validationCallback={res =>
                                            this.setState({hasPurchaseDateError: res, validate: false})
                                        }
                                        validationOption={{
                                            name: 'purchaseDate',
                                            check: true,
                                            required: true,
                                        }}
                                        onChange={(data) => {
                                            this.setState({purchaseDate: data})
                                        }}/>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="10"
                                    id={"manufacture"}
                                    name="manufacture"
                                    type="text"
                                    value={this.state.manufacture}
                                    placeholder="Manufacture"
                                    onChange={(manufacture) => {
                                        this.setState({manufacture});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasManufactureError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Manufacture",
                                        check: true,
                                        required: true
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="11"
                                    id={"modelNo"}
                                    name="modelNo"
                                    type="text"
                                    value={this.state.modelNo}
                                    placeholder="Model Number"
                                    onChange={(modelNo) => {
                                        this.setState({modelNo});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasModelNoError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "ModelNo",
                                        check: true,
                                        required: true
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <FormGroup
                                    helperText={this.state.dateExpErrorMsg}
                                    label="">
                                    <DateInput
                                        tabIndex="12"
                                        id={"expiryDate"}
                                        name={"expiryDate"}
                                        formatDate={date => date.toLocaleString('es-ES', {year: "numeric", month: "2-digit", day: "numeric"})}
                                        parseDate={str => new Date(str)}
                                        placeholder={"Existing Policy Expiry Date"}
                                        value={this.state.expiryDate}
                                        validate={this.state.expiryDate}
                                        className={"PurchaseInputDate"}
                                        onBlur={e => {
                                        }}
                                        validationCallback={res =>
                                            this.setState({hasExpiryDateError: res, validate: false})
                                        }
                                        validationOption={{
                                            name: 'expiryDate',
                                            check: true,
                                            required: true,
                                        }}
                                        onChange={(data) => {
                                            this.setState({expiryDate: data})
                                        }}/>
                                </FormGroup>
                            </div>
                        </div>

                    </div>

                    {/*<div className="app-bots-checker">*/}
                    {/*<h3>Are you Human ?</h3>*/}
                    {/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>    */}
                    {/*</div>*/}
                    <Button type="submit" className="mt-4" text="Submit" intent="primary"
                            loading={this.state.loader}/>
                </form>
            </>

        );
    }
}
