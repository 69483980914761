import React from 'react';
import {Link} from 'react-router-dom';
import Thanku from '../../../img/Thanku.png';

export default class Success extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className="app-success-wrapper">
                    <div className="container">
                        <div className="app-success">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="app-above">
                                        <img src={Thanku} alt="" />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="app-below">
                                        <p className="mb-0">Thank you for contacting us. We have forwarded your information to the appropriate contact and we will
                                            be in touch as soon as possible.</p>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <Link to="" className="bg-primary">Go To Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}