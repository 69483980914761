import React from 'react';
import {Textbox, Radiobox, Select} from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../Config';
import constant from '../common/constant.js';
import {Card, Elevation, FormGroup, Button} from '@blueprintjs/core';
import {Toaster, Intent, Position} from '@blueprintjs/core';
import {Auth, ssoInstance} from "gg-react-utilities";
import {numberFormat} from '../common/CommonFunction.js';
import Loader from "../common/Loader";

export default class CloudForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: null,
            userId: "",
            name: "",
            email: "",
            mobile: "",
            storage_plan: "",
            options: [],
            validate: false,
            hasNameError: true,
            hasEmailError: true,
            hasMobileError: true,
            hasStoragePlanError: true,
            loader: false,
            loadingStatus: true
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        ssoInstance.get(config.API_URL + "v1/cloud/plan").then((response) => {
            if (response.data.status) {
                let list = response.data.data.map(p => ({
                    id: p.plan_id,
                    planName: p.plan,
                    name: p.plan + "  - " + 'Rs.' + p.amount + '/-',
                    amount: p.amount,
                    GST: p.GST,
                    pg_per: p.pg_per,
                }));
                list = [{
                    id: "",
                    name: "Select Cloud Storage Plan"
                }, ...list];
                this.setState({
                    options: list
                })
            } else {
                Toaster.create({position: Position.TOP_RIGHT}).show({
                    message: response.data.message,
                    intent: Intent.DANGER
                });
            }
        });
    }

    handlePlanChange = (value) => {
        this.setState({
            loadingStatus: true
        });
        this.setState({storage_plan: value});
        let selectedPlan = this.state.options.filter((plan) => {
            return plan.id == value;
        });

        if (selectedPlan.length > 0 && selectedPlan[0].id !== "") {
            selectedPlan = selectedPlan[0];
            this.setState({
                selectedPlanName: selectedPlan.planName,
                selectedPlanAmount: selectedPlan.amount,
                GST_per: selectedPlan.GST,
                PG_PER: selectedPlan.pg_per,
                GST_Charges: (selectedPlan.amount * selectedPlan.GST / 100),
                PG_Charges: (selectedPlan.amount * selectedPlan.pg_per / 100)
            });
            setTimeout(() => {
                this.setState({
                    loadingStatus: false
                })
            }, 1000);
        }
    };

    toggleValidating(validate) {
        this.setState({validate});
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn: res}, () => {
                    if (this.state.isLoggedIn !== true) {
                        Auth.login(true);
                    }
                })
            });
        }, 1)
    }

    onSubmit(event) {
        this.toggleValidating(true);
        if (!this.state.hasNameError &&
            !this.state.hasEmailError &&
            !this.state.hasMobileError &&
            !this.state.hasStoragePlanError) {
            this.setState({
                loader: true
            });
            if (this.state.isLoggedIn) {
                ssoInstance.post(config.API_URL + "v1/cloud/plan", {
                    userId: Auth.user().user_id,
                    name: this.state.name,
                    userName: Auth.user().username,
                    mobile: this.state.mobile,
                    email: this.state.email,
                    plan_id: this.state.storage_plan
                }).then((response) => {
                    const sources = response.data;
                    if (sources.status === true) {
                        const paywithpayubiz = sources.data;
                        let form = document.createElement("form");
                        form.setAttribute("method", 'POST');
                        form.setAttribute("action", paywithpayubiz.url);
                        for (let key in paywithpayubiz) {
                            let hiddenField = document.createElement("input");
                            hiddenField.setAttribute("type", "hidden");
                            hiddenField.setAttribute("name", key);
                            hiddenField.setAttribute("value", paywithpayubiz[key]);
                            form.appendChild(hiddenField);
                        }
                        //
                        document.body.appendChild(form);
                        form.submit();
                    }
                    if (response.data.subject === "Error") {
                        Toaster.create({position: Position.TOP_RIGHT}).show({
                            message: response.data.message,
                            intent: Intent.DANGER
                        });
                    } else if (response.data.subject === "Success") {
                        window.location.href = config.APP_URL + "success";
                    }
                }).catch(function (error) {
                    console.log("error", error);
                });
            } else {
                this.setState({
                    name: "",
                    email: "",
                    mobile: "",
                    storage_plan: "",
                    validate: false,
                    hasNameError: true,
                    hasEmailError: true,
                    hasMobileError: true,
                    hasStoragePlanError: true,
                    loader: false
                });
                Auth.login(true)
            }
        } else {

        }
        event.preventDefault();
        return true;
    }

    render() {
        return (
            <React.Fragment>
                <div className="container app-form-card mb-5">
                    <Card interactive={true} elevation={Elevation.THREE}
                          className="pt-4 pb-5 px-5 p-s-0 p-sm-4 pb-s-5 pb-sm-5 justify-content-center d-flex">
                        <div className="col-sm-12 col-12 mt-2">


                            <form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3"
                                  ref={(el) => this.myFormRef = el}>
                                <div className="row">

                                    <div className="col-xl-8 col-lg-10 col-md-11 col-sm-12 col-12 py-4 px-2">

                                        <div className="row mb-2">
                                            <div className="col-sm-12 col-12 mt-2">
                                                <div className="mb-4">
                                                    <Textbox
                                                        tabIndex="5"
                                                        id={"name"}
                                                        name="name"
                                                        type="text"
                                                        value={this.state.name}
                                                        placeholder="Enter Name"
                                                        onChange={(name) => {
                                                            this.setState({name});
                                                        }}
                                                        onFocus={() => {
                                                            this.checkAuth()
                                                        }}
                                                        onBlur={e => {
                                                        }}
                                                        validate={this.state.validate}
                                                        validationCallback={res =>
                                                            this.setState({hasNameError: res, validate: false})
                                                        }
                                                        validationOption={{
                                                            name: "Name",
                                                            check: true,
                                                            required: true,
                                                            customFunc: name => {
                                                                if (/^[a-zA-Z]+$/.test(name)) {
                                                                    return true;
                                                                } else {
                                                                    return "Is not a valid name.";
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="mb-4">
                                                    <Textbox
                                                        tabIndex="6"
                                                        id={"email"}
                                                        name="email"
                                                        type="text"
                                                        value={this.state.email}
                                                        placeholder="Enter Email"
                                                        onChange={(email) => {
                                                            this.setState({email});
                                                        }}
                                                        onBlur={e => {
                                                        }}
                                                        onFocus={() => {
                                                            this.checkAuth()
                                                        }}
                                                        validate={this.state.validate}
                                                        validationCallback={res =>
                                                            this.setState({hasEmailError: res, validate: false})
                                                        }
                                                        validationOption={{
                                                            name: "Email ID",
                                                            check: true,
                                                            required: true,
                                                            customFunc: email => {
                                                                if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                                                    return true;
                                                                } else {
                                                                    return "Is not a valid email address";
                                                                }
                                                            }
                                                        }}
                                                        // validationOption={{
                                                        //     name: "Email",
                                                        //     check: true,
                                                        //     required: true,
                                                        // }}
                                                    />
                                                </div>

                                                <div className="mb-4">
                                                    <Textbox
                                                        tabIndex="6"
                                                        id={"mobile"}
                                                        name="mobile"
                                                        type="text"
                                                        value={this.state.mobile}
                                                        placeholder="Enter Mobile Number"
                                                        onChange={(mobile) => {
                                                            this.setState({mobile});
                                                        }}
                                                        onBlur={e => {
                                                        }}
                                                        onFocus={() => {
                                                            this.checkAuth()
                                                        }}
                                                        validate={this.state.validate}
                                                        validationCallback={res =>
                                                            this.setState({hasMobileError: res, validate: false})
                                                        }
                                                        validationOption={{
                                                            name: "Mobile Number",
                                                            check: true,
                                                            required: true,
                                                            customFunc: mobile => {
                                                                if (validator.isMobilePhone(mobile, "en-IN")) {
                                                                    return true;
                                                                } else {
                                                                    return "Is not a valid mobile number.";
                                                                }
                                                            }
                                                        }}/>
                                                </div>

                                                <div className="mb-4">
                                                    <Select
                                                        tabIndex="9"
                                                        id={"storage_plan"}
                                                        name="storage_plan"
                                                        disabled={false}
                                                        value={this.state.storage_plan}
                                                        onBlur={(e) => {
                                                        }}
                                                        onFocus={() => {
                                                            this.checkAuth()
                                                        }}
                                                        // onChange={(storage_plan, e) => {
                                                        //     this.setState({storage_plan});
                                                        // }}
                                                        onChange={this.handlePlanChange}
                                                        validate={this.state.validate}
                                                        validationCallback={res =>
                                                            this.setState({hasStoragePlanError: res, validate: false})
                                                        }
                                                        optionList={this.state.options}
                                                        validationOption={{
                                                            name: 'Cloud Storage Plan',
                                                            check: true,
                                                            required: true,
                                                        }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-12 pr-0">
                                        <div className="card border-0" style={{background: "#F8FCFF"}}>
                                            <div className="card-body p-4 text-left">
                                                <div className="col-xl-12 col-12">
                                                    <p className="mb-5 mt-2 bp3-text-xlarge font-weight-normal text-dark cart-title">
                                                        Payment Summery
                                                    </p>
                                                    {/*{*/}
                                                    {/*    this.state.loading === true ?*/}
                                                    {/*        <div className="mb-2">*/}
                                                    {/*            <div className="bp3-skeleton"*/}
                                                    {/*                 style={{width: "100%", height: "150px"}}>&nbsp;</div>*/}
                                                    {/*        </div>*/}
                                                    {/*        :*/}
                                                    <div className="clearfix"/>
                                                    {this.state.loadingStatus === true ?
                                                        // <div className="text-center">
                                                        //     <Loader/>
                                                        // </div>
                                                        // <div className="mb-2">
                                                        //     <div className="bp3-skeleton"
                                                        //          style={{width: "100%", height: "150px"}}>&nbsp;</div>
                                                        // </div>

                                                        <div className="row">
                                                            <React.Fragment>
                                                                <div className="col-md-8">
                                                                    <p className="mb-4 text-left mt-1 bp3-skeleton">
                                                                        <small> {"skeleton"}</small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="mb-4 text-right mt-1 bp3-skeleton">
                                                                        <small>{"skeleton"}</small>
                                                                    </p>
                                                                </div>

                                                                <div className="col-md-8">
                                                                    <p className="mb-4 text-left mt-1 bp3-skeleton">
                                                                        <small> {"skeleton"}</small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="mb-4 text-right mt-1 bp3-skeleton">
                                                                        <small>{"skeleton"}</small>
                                                                    </p>
                                                                </div>

                                                                <div className="col-md-8">
                                                                    <p className="mb-4 text-left mt-1 bp3-skeleton">
                                                                        <small> {"skeleton"}</small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="mb-4 text-right mt-1 bp3-skeleton">
                                                                        <small>{"skeleton"}</small>
                                                                    </p>
                                                                </div>

                                                                <div className="col-md-8">
                                                                    <p className="mb-4 text-left mt-1 bp3-skeleton">
                                                                        <small>{"skeleton"}</small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="mb-4 text-right mt-1 bp3-skeleton">
                                                                        <small>{"skeleton"}</small>
                                                                    </p>
                                                                </div>

                                                                <div
                                                                    className="col-md-8">
                                                                    <p className="mb-4 text-left mt-2 bp3-skeleton">
                                                                        <small>
                                                                            <b>{"skeleton"}</b></small>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="col-md-4 border-top">
                                                                    <p className="mb-4 text-right mt-2 bp3-skeleton">
                                                                        <small>{"skeleton"}</small>
                                                                    </p>
                                                                </div>
                                                            </React.Fragment>
                                                        </div>

                                                        :
                                                        <div className="row">
                                                            <React.Fragment>
                                                                <div className="col-md-8">
                                                                    <p className="mb-4 text-left mt-1">
                                                                        <small> {"Cloud Storage Plan"}</small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="mb-4 text-right mt-1">
                                                                        <small>{this.state.selectedPlanName}</small>
                                                                    </p>
                                                                </div>

                                                                <div className="col-md-8">
                                                                    <p className="mb-4 text-left mt-1">
                                                                        <small> {"Sub Total"}</small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="mb-4 text-right mt-1">
                                                                        <small>{numberFormat(this.state.selectedPlanAmount)}</small>
                                                                    </p>
                                                                </div>

                                                                <div className="col-md-8">
                                                                    <p className="mb-4 text-left mt-1">
                                                                        <small> {"GST Charges"} &nbsp;({this.state.GST_per})&#x25;&nbsp;</small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="mb-4 text-right mt-1">
                                                                        <small>{numberFormat(this.state.GST_Charges)}</small>
                                                                    </p>
                                                                </div>

                                                                <div className="col-md-8">
                                                                    <p className="mb-4 text-left mt-1">
                                                                        <small> {"PG Charges"} &nbsp;({this.state.PG_PER})&#x25;&nbsp;</small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="mb-4 text-right mt-1">
                                                                        <small>{numberFormat(this.state.PG_Charges)}</small>
                                                                    </p>
                                                                </div>

                                                                <div
                                                                    className="col-md-8">
                                                                    <p className="mb-4 text-left mt-2">
                                                                        <small>
                                                                            <b>{"Grand Total:"}</b></small>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="col-md-4 border-top">
                                                                    <p className="mb-4 text-right mt-2">
                                                                        <small>{numberFormat(Number(this.state.GST_Charges) + Number(this.state.PG_Charges) + Number(this.state.selectedPlanAmount))}</small>
                                                                    </p>
                                                                </div>
                                                            </React.Fragment>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-xl-12 col-12 mt-2 mb-2 pull-right">
                                                    {/*<Button*/}
                                                    {/*    className="bp3-button bp3-small btn-order bp3-intent-primary pull-right"*/}
                                                    {/*    loading={this.state.loader}*/}
                                                    {/*    type="Submit"*/}
                                                    {/*    // onClick={() => {*/}
                                                    {/*    //     let response = {*/}
                                                    {/*    //         name: this.state.name,*/}
                                                    {/*    //         email: this.state.email,*/}
                                                    {/*    //         mobile: this.state.mobile,*/}
                                                    {/*    //         plan_id: this.state.storage_plan,*/}
                                                    {/*    //     };*/}
                                                    {/*    //     localStorage.setItem('preOrderData', JSON.stringify(response));*/}
                                                    {/*    //     this.getStoragePlan(this.state.storage_plan, response);*/}
                                                    {/*    // }}*/}
                                                    {/*>Pay Now*/}
                                                    {/*</Button>*/}
                                                    <Button type="Submit"
                                                            loading={this.state.loader}
                                                            text="Submit" intent="primary"
                                                            loading={this.state.loader}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </form>
                        </div>

                    </Card>
                    <div className="clearfix"></div>
                </div>

            </React.Fragment>
        );
    }
}
