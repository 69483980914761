import React from 'react';
import {Textbox, Radiobox, Select} from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../../Config';
import constant from '../../common/constant';
import {Card, Elevation, FormGroup, Button} from '@blueprintjs/core';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import {Auth, ssoInstance} from "gg-react-utilities";

export default class Form extends React.Component{
    constructor(props){
        super(props);
        this.state={
			isLoggedIn: null,
			userId:"",
            name:"",
            email:"",
            mobile:"",
            city:"",
            state:"",
            brand:"",
            type:"",
            fuelValue:"",
            cashValue:"",
            validate:false,
            hasNameError:true,
			hasEmailError:true,
			hasMobileError:true,
			hasCityError:true,
			hasStateError:true,
			hasBrandError:true,
            hasTypeError:true,
            hasFuelError:true,
            hasCashError:true,
            loader:false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

	async checkAuth(){
		setTimeout(()=>{
			Auth.isLoggedInAsync().then((res) => {
				this.setState({isLoggedIn:res},()=>{
					if(this.state.isLoggedIn!==true){
						Auth.login(true);
					}
				})
			});
		},1)
	}

    onSubmit(event){
        this.toggleValidating(true);
        if( !this.state.hasNameError &&
			!this.state.hasEmailError &&
			!this.state.hasMobileError &&
			!this.state.hasCityError &&
			!this.state.hasStateError &&
			!this.state.hasBrandError &&
        	!this.state.hasTypeError &&
            !this.state.hasFuelError &&
            !this.state.hasCashError){
			this.setState({
				loader:true
			});

			if (this.state.isLoggedIn) {
				ssoInstance.post(config.API_URL + "vehicle", {
					userId:Auth.user().user_id,
					name: this.state.name,
          			userName:Auth.user().username,
					mobile: this.state.mobile,
					email: this.state.email,
					city: this.state.city,
					state: this.state.state,
					vehicle_type: this.state.type,
					model: this.state.brand,
					fuel_type: this.state.fuelValue,
					payment_method: this.state.cashValue
				}).then((response) => {
					const sources = response.data;
					if( sources.status === true ){
						window.location.href = config.APP_URL + "success";

					} else {
						Toaster.create({position: Position.TOP_RIGHT}).show({
							message: response.data.message,
							intent: Intent.DANGER
						});
						this.setState({
							loader:false
						});
					}
				}).catch((error) => {
					if(error){
						const sources = error.response.data;
						if(sources.error){
							Object.keys(sources.error).forEach((key)=>{

								error.response.data.error[key].forEach((error)=>{

									Toaster.create({
										position: Position.TOP,
									}).show({message: error, intent:Intent.DANGER});

								});

							});

						}else{
							if(sources.status===false){

								Toaster.create({
									position: Position.TOP,
								}).show({message: sources.message, intent:Intent.DANGER});

							}else{

								Toaster.create({
									position: Position.TOP,
								}).show({message: sources.message, intent:Intent.DANGER});
							}
						}
						// dispatch(actions.paymentCatchResponse(sources));
					}
					this.setState({
						loader:false
					});
				});
			}else{
				this.setState({
					name:"",
					email:"",
					mobile:"",
					city:"",
					state:"",
					brand:"",
					type:"",
					fuelValue:"",
					cashValue:"",
					validate:false,
					hasNameError:true,
					hasEmailError:true,
					hasMobileError:true,
					hasCityError:true,
					hasStateError:true,
					hasBrandError:true,
					hasTypeError:true,
					hasFuelError:true,
					hasCashError:true,
					loader:false
				});
				Auth.login(true)
			}
        }else{

        }
		event.preventDefault();
        return true;
    }

    render(){

        return(
			<React.Fragment>
				<div className="container app-form-card mb-5">
					<Card interactive={true} elevation={Elevation.THREE}
						  className="pt-4 pb-5 px-5 p-s-0 p-sm-4 pb-s-5 pb-sm-5 justify-content-center d-flex">


						<div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 py-4 px-2">
							<form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3"
								  ref={(el) => this.myFormRef = el}>
								<div className="row mb-4">
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="1"
												id={"Name"}
												placeholder="Full Name"
												value={this.state.name}
												onBlur={e => {
												}}
												onChange={(name, e) => {
													this.setState({name});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasNameError: res, validate: false})
												}
												validationOption={{
													name: 'Name',
													check: true,
													required: true
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="1"
												id={"phone"}
												name="phone"
												type="text"
												value={this.state.mobile}
												placeholder="Mobile Number"
												onChange={(mobile) => {
													this.setState({mobile});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												onBlur={e => {
												}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasMobileError: res, validate: false})
												}
												validationOption={{
													name: "Mobile Number",
													check: true,
													required: true,
													customFunc: mobile => {
														if (validator.isMobilePhone(mobile, "en-IN")) {
															return true;
														} else {
															return "Is not a valid mobile number.";
														}
													}
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="3"
												id={"email"}
												name="email"
												type="text"
												value={this.state.email}
												placeholder="Email ID"
												onChange={(email) => {
													this.setState({email});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												onBlur={e => {
												}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasEmailError: res, validate: false})
												}
												validationOption={{
													name: "Email ID",
													check: true,
													required: true,
													customFunc: email => {
														if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
															return true;
														} else {
															return "Is not a valid email address";
														}
													}
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="4"
												id={"city"}
												name="city"
												type="text"
												value={this.state.city}
												placeholder="City/RTO"
												onChange={(city) => {
													this.setState({city});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												onBlur={e => {
												}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasCityError: res, validate: false})
												}
												validationOption={{
													name: "City",
													check: true,
													required: true,
												}}/>
										</div>
									</div>
									<div className="col-sm-12 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="5"
												id={"state"}
												name="state"
												type="text"
												value={this.state.state}
												placeholder="State"
												onChange={(state) => {
													this.setState({state});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												onBlur={e => {
												}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasStateError: res, validate: false})
												}
												validationOption={{
													name: "State",
													check: true,
													required: true,
												}}/>
										</div>
									</div>
								</div>
								<FormGroup
									label="Vehicle Type:" className="vehicle_type">
									<Radiobox
										tabIndex="7"
										id={"type"}
										name="type"
										disabled={false}
										value={this.state.type}
										onBlur={(e) => {
										}}
										onFocus = {( ) => {this.checkAuth()}}
										onChange={(type) => {
											this.setState({type});
										}}
										validate={this.state.validate}
										validationCallback={res =>
											this.setState({hasTypeError: res, validate: false})
										}
										customStyleContainer={{
											display: "flex",
											/*justifyContent: "space-between"*/
										}}
										optionList={constant.VEHICLE_TYPE_LIST}
										validationOption={{
											name: 'Vehicle Type',
											check: true,
											required: true,
										}}/>
								</FormGroup>
								<div className="mb-4">
									<Textbox
										tabIndex="6"
										id={"brand"}
										name="brand"
										type="text"
										value={this.state.brand}
										placeholder="Brand Name"
										onChange={(brand) => {
											this.setState({brand});
										}}
										onBlur={e => {
										}}
										onFocus = {( ) => {this.checkAuth()}}
										validate={this.state.validate}
										validationCallback={res =>
											this.setState({hasBrandError: res, validate: false})
										}
										validationOption={{
											name: "Brand Name",
											check: true,
											required: true,
										}}/>
								</div>
								<div className="row">
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Select
												tabIndex="8"
												id={"fuel"}
												name="fuelValue"
												disabled={false}
												value={this.state.fuelValue}
												onBlur={(e) => {
												}}
												onChange={(fuelValue) => {
													this.setState({fuelValue});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasFuelError: res, validate: false})
												}
												optionList={constant.FUEL_TYPE_LIST}
												validationOption={{
													name: 'Fuel Type',
													check: true,
													required: true,
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Select
												tabIndex="9"
												id={"cash"}
												name="cash"
												disabled={false}
												value={this.state.cashValue}
												onBlur={(e) => {
												}}
												onFocus = {( ) => {this.checkAuth()}}
												onChange={(cashValue, e) => {
													this.setState({cashValue});
												}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasCashError: res, validate: false})
												}
												optionList={constant.CASH_TYPE_LIST}
												validationOption={{
													name: 'Cash / Finance',
													check: true,
													required: true,
												}}/>
										</div>
									</div>
								</div>
								{/*<div className="app-bots-checker mt-2">*/}
								{/*<h3>Are you Human ?</h3>*/}
								{/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>*/}
								{/**/}
								{/*</div>*/}
								<Button type="submit" text="Submit" intent="primary" className="my-3"
										loading={this.state.loader}/>
								<div className="clearfix"></div>
							</form>
						</div>

					</Card>
					<div className="clearfix"></div>
				</div>

			</React.Fragment>
        );
    }
}
