import React from 'react';
// import Form from './include/form';
import {Select, Textbox} from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../../Config';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import {Auth, ssoInstance} from "gg-react-utilities";
import constant from "../../common/constant";

export default class LifeInsuranceInquiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            userId: "",
            name: "",
            email: "",
            mobile: "",
            city: "",
            state: "",
            pincode: "",
            buyPolicy: "",
            sumInsured: "",
            gender: "",
            age: "",
            ageList:[],
            types: '2',
            validate: false,
            hasNameError: true,
            hasEmailError: true,
            hasMobileError: true,
            hasCityError: true,
            hasStateError: true,
            hasPincodeError: true,
            hasBuyPolicyError: true,
            hasSumInsuredError: true,
            hasGenderError: true,
            hasAgeError: true,
            hasTypeError: true,
            loader: false
        }
        this.onSubmit= this.onSubmit.bind(this);
    }
    componentDidMount() {

        this.setPrimaryInsuredAge();
    }

    setPrimaryInsuredAge = () =>{
        let ageList = [
            {id:"", name: "Primary Insured Age"},
            {id:"0-17 Years", name: "0-17 Years"}]

        for(let i = 18; i < 100; i++){
            ageList.push({id: i+" Years", name: i+" Years"});
        }
        this.setState({
            ageList
        })
    }

    toggleValidating(validate) {
        this.setState({validate});
    }

    onSubmit(event){
        event.preventDefault();
        this.toggleValidating(true);
        if(!this.state.hasNameError &&
            !this.state.hasEmailError &&
            !this.state.hasMobileError &&
            !this.state.hasCityError &&
            !this.state.hasStateError &&
            !this.state.hasPincodeError &&
            !this.state.hasBuyPolicyError &&
            !this.state.hasSumAssuredError &&
            !this.state.hasGenderError &&
            !this.state.hasAgeError){
            this.setState({
                loader:true
            });
            if (this.state.isLoggedIn){
                ssoInstance.post(config.API_URL+"insurance", {
                    userId:Auth.user().user_id,
                    userName:Auth.user().username,
                    name: this.state.name,
                    mobile: this.state.mobile,
                    email: this.state.email,
                    city: this.state.city,
                    state: this.state.state,
                    pincode: this.state.pincode,
                    health_buyPolicyFor: this.state.buyPolicy,
                    health_sumInsured: this.state.sumInsured,
                    health_primaryInsuredGender: this.state.gender,
                    health_primaryInsuredAge: this.state.age,
                    insurances_type: this.state.types
                }).then((response) => {
                    const sources = response.data;
                    if( sources.status === true ){
                        window.location.href = config.APP_URL + "success";

                    } else {
                        Toaster.create({position: Position.TOP_RIGHT}).show({
                            message: response.data.message,
                            intent: Intent.DANGER
                        });
                        this.setState({
                            loader:false
                        });
                    }
                }).catch((error) => {
                    if(error){
                        const sources = error.response.data;
                        console.log(sources);
                        if(sources.error){
                            Object.keys(sources.error).forEach((key)=>{

                                error.response.data.error[key].forEach((error)=>{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent:Intent.DANGER});

                                });

                            });

                        }else{
                            if(sources.status===false){

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});

                            }else{

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});
                            }
                        }
                        // dispatch(actions.paymentCatchResponse(sources));
                    }
                    this.setState({
                        loader:false
                    });
                });
            } else {
                this.setState({
                    name:"",
                    email:"",
                    mobile:"",
                    city:"",
                    state:"",
                    pincode:"",
                    buyPolicy: "",
                    sumInsured: "",
                    gender: "",
                    age: "",
                    types:'2',
                    validate:false,
                    hasNameError:true,
                    hasEmailError:true,
                    hasMobileError:true,
                    hasCityError:true,
                    hasStateError:true,
                    hasPincodeError:true,
                    hasBuyPolicyError: true,
                    hasSumInsuredError: true,
                    hasGenderError: true,
                    hasAgeError: true,
                    hasTypeError:true,
                    loader:false
                });
                Auth.login(true)
            }
        }else{

        }
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn: res}, () => {
                    if (res === false) {
                        Auth.login(true);
                    }
                })
            });
        }, 1)
    }

    render() {
        return (
            <>
                <form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3 pb-5"
                      ref={(el) => this.myFormRef = el}>
                    <div className="row mb-4">
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="1"
                                    id={"Name"}
                                    placeholder="Full Name"
                                    value={this.state.name}
                                    onBlur={e => {
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onChange={(name, e) => {
                                        this.setState({name});
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasNameError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: 'Name',
                                        check: true,
                                        required: true
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="2"
                                    id={"phone"}
                                    name="phone"
                                    type="text"
                                    value={this.state.mobile}
                                    placeholder="Mobile Number"
                                    onFocus={() => this.checkAuth()}
                                    onChange={(mobile) => {
                                        this.setState({mobile});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasMobileError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Mobile Number",
                                        check: true,
                                        required: true,
                                        customFunc: mobile => {
                                            if (validator.isMobilePhone(mobile, "en-IN")) {
                                                return true;
                                            } else {
                                                return "Is not a valid mobile number.";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="3"
                                    id={"email"}
                                    name="email"
                                    type="text"
                                    value={this.state.email}
                                    onFocus={() => this.checkAuth()}
                                    placeholder="Email ID"
                                    onChange={(email) => {
                                        this.setState({email});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasEmailError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Email ID",
                                        check: true,
                                        required: true,
                                        customFunc: email => {
                                            if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                                return true;
                                            } else {
                                                return "Is not a valid email address";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="4"
                                    id={"city"}
                                    name="city"
                                    type="text"
                                    value={this.state.city}
                                    placeholder="City"
                                    onChange={(city) => {
                                        this.setState({city});
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasCityError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "City",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="5"
                                    type="text"
                                    value={this.state.state}
                                    placeholder="State"
                                    onChange={(state) => {
                                        this.setState({state});
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasStateError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "State",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="6"
                                    id={"pincode"}
                                    name="pincode"
                                    type="text"
                                    value={this.state.pincode}
                                    placeholder="Pincode"
                                    onChange={(pincode) => {
                                        this.setState({pincode});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasPincodeError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Pincode",
                                        check: true,
                                        required: true,
                                        customFunc: pincode => {
                                            if (validator.isPostalCode(pincode, "IN")) {
                                                return true;
                                            } else {
                                                return "Is not a valid pincode.";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Select
                                    tabIndex="7"
                                    id={"buyPolicy"}
                                    name="buyPolicy"
                                    disabled={false}
                                    value={this.state.buyPolicy}
                                    onChange={(buyPolicy) => {
                                        this.setState({buyPolicy});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasBuyPolicyError: res, validate: false})
                                    }
                                    optionList={
                                        [
                                            { id: "", name:  "Buy Policy For"},
                                            { id: "Individual", name: "Individual" },
                                            { id: "Family", name: "Family" }
                                        ]
                                    }
                                    validationOption={{
                                        name: 'Buy Policy For',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2 setSelectListHight">
                            <div className="mb-4">
                                <Select
                                    tabIndex="8"
                                    id={"sumInsured"}
                                    name="sumInsured"
                                    disabled={false}
                                    value={this.state.sumInsured}
                                    onChange={(sumInsured) => {
                                        this.setState({sumInsured});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasSumInsuredError: res, validate: false})
                                    }
                                    optionList={constant.HEALTH_SUM_INSURED_LIST}
                                    validationOption={{
                                        name: 'Sum Insured',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Select
                                    tabIndex="9"
                                    id="gender"
                                    name="gender"
                                    disabled={false}
                                    value={this.state.gender}
                                    onChange={(gender) => {
                                        this.setState({gender});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasGenderError: res, validate: false})
                                    }
                                    optionList={
                                        [
                                            { id: "", name:  "Primary Insured Gender"},
                                            { id: "Male", name: "Male" },
                                            { id: "Female", name: "Female" }
                                        ]
                                    }
                                    validationOption={{
                                        name: 'Primary Insured Gender',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2 setSelectListHight">
                            <div className="mb-4">
                                <Select
                                    tabIndex="10"
                                    id="age"
                                    name="age"
                                    disabled={false}
                                    value={this.state.age}
                                    onChange={(age) => {
                                        this.setState({age});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasAgeError: res, validate: false})
                                    }
                                    optionList={this.state.ageList}
                                    validationOption={{
                                        name: 'Primary Insured Age',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                    </div>


                    {/*<div className="app-bots-checker">*/}
                    {/*<h3>Are you Human ?</h3>*/}
                    {/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>    */}
                    {/*</div>*/}
                    <Button type="submit" className="mt-4" text="Submit" intent="primary"
                            loading={this.state.loader}/>
                </form>
            </>

        );
    }
}
