import React from 'react';
import Navbar from '../common/navbar';
import Form from './include/form';
import PbLogo from '../../../core/components/Web/images/logo-pb.png';

export default class InsuranceInquiry extends React.Component{
    constructor(props){
        super(props);
        document.title = "Insurance Inquiry | Global Garner";
    }
    
    componentDidMount() {

    }

    render(){
        return(
            <React.Fragment>
                <div className="header-below px-3">
                    <div className="header-below-content">
                        <img src={PbLogo} style={{width:'300px'}} />
                        <h1 className="heading">India's Best Insurance Destination</h1>
                        {/*<div className="heading-desc">
                            <p>Insure yourself, your family and your belongings.....
                                We are here to fullfil all your insurance needs with many cashback options.</p>
                            <p>Powered by <a href="https://www.policybazaar.com/" target="_blank" style={{color:"#B1D3FF"}}>PolicyBazaar.com</a></p>
                        </div>*/}
                    </div>
                </div>
                <Navbar />
                <Form />
            </React.Fragment>
        );
    }
}
