import React from 'react';
import {Textbox, Radiobox, Select} from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../../Config';
import constant from '../../common/constant';
import {Card, Elevation, FormGroup, Button} from '@blueprintjs/core';
import { Toaster, Intent, Position } from '@blueprintjs/core';
import {Auth, ssoInstance} from "gg-react-utilities";

export default class Form extends React.Component{
    constructor(props){
        super(props);
        this.state={
			isLoggedIn: null,
			userId:"",
			user_name:"",
			itr_file:"",
			user_relation:"",
			user_contact:"",
			tax_payer_contact:"",
			is_previous_itr_filed:"",
			approx_last_year_income:"",
			nature_of_income:"",
            validate:false,
            hasUserNameError:true,
            hasItrFileError:true,
			hasUserRelationError:true,
			hasUserContactError:true,
			hasTaxPlayerContactError:true,
			hasIsPreviousItrFiledError:true,
			hasApproxLastYearIncomeError:true,
            hasNatureOfIncomeError:true,
            loader:false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

	async checkAuth(){
		setTimeout(()=>{
			Auth.isLoggedInAsync().then((res) => {
				this.setState({isLoggedIn:res},()=>{
					if(this.state.isLoggedIn!==true){
						Auth.login(true);
					}
				})
			});
		},1)
	}

    onSubmit(event){
        this.toggleValidating(true);

        if( !this.state.hasUserNameError &&
			!this.state.hasItrFileError &&
			!this.state.hasUserRelationError &&
			!this.state.hasUserContactError &&
			!this.state.hasTaxPlayerContactError &&
			!this.state.hasIsPreviousItrFiledError &&
        	!this.state.hasApproxLastYearIncomeError &&
            !this.state.hasNatureOfIncomeError){
			this.setState({
				loader:true
			});

			if (this.state.isLoggedIn) {
				ssoInstance.post(config.API_URL + "itrInquiry", {
					userId:22323,//Auth.user().user_id,
					user_name: this.state.user_name,
					itr_file: this.state.itr_file,
					user_relation: this.state.user_relation,
					user_contact: this.state.user_contact,
					tax_payer_contact: this.state.tax_payer_contact,
					is_previous_itr_filed: this.state.is_previous_itr_filed,
					approx_last_year_income: this.state.approx_last_year_income,
					nature_of_income: this.state.nature_of_income,
				}).then((response) => {
					const sources = response.data;
					if( sources.status === true ){
						window.location.href = config.APP_URL + "success";

					} else {
						Toaster.create({position: Position.TOP_RIGHT}).show({
							message: response.data.message,
							intent: Intent.DANGER
						});
						this.setState({
							loader:false
						});
					}
				}).catch((error) => {
					if(error){
						const sources = error.response.data;
						if(sources.error){
							Object.keys(sources.error).forEach((key)=>{

								error.response.data.error[key].forEach((error)=>{

									Toaster.create({
										position: Position.TOP,
									}).show({message: error, intent:Intent.DANGER});

								});

							});

						}else{
							if(sources.status===false){

								Toaster.create({
									position: Position.TOP,
								}).show({message: sources.message, intent:Intent.DANGER});

							}else{

								Toaster.create({
									position: Position.TOP,
								}).show({message: sources.message, intent:Intent.DANGER});
							}
						}
						// dispatch(actions.paymentCatchResponse(sources));
					}
					this.setState({
						loader:false
					});
				});
			}else{
				this.setState({
					itr_file:"",
					user_relation:"",
					user_contact:"",
					tax_payer_contact:"",
					is_previous_itr_filed:"",
					approx_last_year_income:"",
					nature_of_income:"",
					validate:false,
					hasItrFileError:true,
					hasUserRelationError:true,
					hasUserContactError:true,
					hasTaxPlayerContactError:true,
					hasIsPreviousItrFiledError:true,
					hasApproxLastYearIncomeError:true,
					hasNatureOfIncomeError:true,
					loader:false
				});
				Auth.login(true)
			}
        }else{
			Toaster.create({
				position: Position.TOP,
			}).show({message: "Something went wrong!", intent:Intent.DANGER});
        }
		event.preventDefault();
        return true;
    }

    render(){

        return(
			<React.Fragment>
				<div className="container app-form-card mb-5">
					<Card interactive={true} elevation={Elevation.THREE}
						  className="pt-4 pb-5 px-5 p-s-0 p-sm-4 pb-s-5 pb-sm-5 justify-content-center d-flex">


						<div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 py-4 px-2">
							<form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3"
								  ref={(el) => this.myFormRef = el}>
								<div className="row mb-4">
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="1"
												id={"user_name"}
												placeholder="User Name"
												value={this.state.user_name}
												onBlur={e => {
												}}
												onChange={(user_name, e) => {
													this.setState({user_name});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasUserNameError: res, validate: false})
												}
												validationOption={{
													name: 'User Name',
													check: true,
													required: true
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="2"
												id={"itr_file"}
												placeholder="Tax Payer who want to file ITR"
												value={this.state.itr_file}
												onBlur={e => {
												}}
												onChange={(itr_file, e) => {
													this.setState({itr_file});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasItrFileError: res, validate: false})
												}
												validationOption={{
													name: 'Tax Payer who want to file ITR',
													check: true,
													required: true
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="3"
												id={"user_relation"}
												placeholder="Relation with User"
												value={this.state.user_relation}
												onBlur={e => {
												}}
												onChange={(user_relation, e) => {
													this.setState({user_relation});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasUserRelationError: res, validate: false})
												}
												validationOption={{
													name: 'Relation with User',
													check: true,
													required: true
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="4"
												id={"user_contact"}
												name="user_contact"
												type="text"
												value={this.state.user_contact}
												placeholder="Contact No. of User"
												onChange={(user_contact) => {
													this.setState({user_contact});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												onBlur={e => {
												}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasUserContactError: res, validate: false})
												}
												validationOption={{
													name: "Contact No. of User",
													check: true,
													required: true,
													customFunc: mobile => {
														if (validator.isMobilePhone(mobile, "en-IN")) {
															return true;
														} else {
															return "Is not a valid Contact number.";
														}
													}
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="5"
												id={"tax_payer_contact"}
												name="tax_payer_contact"
												type="text"
												value={this.state.tax_payer_contact}
												placeholder="Contact No. of Tax Payer"
												onChange={(tax_payer_contact) => {
													this.setState({tax_payer_contact});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												onBlur={e => {
												}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasTaxPlayerContactError: res, validate: false})
												}
												validationOption={{
													name: "Contact No. of Tax Payer",
													check: true,
													required: true,
													customFunc: mobile => {
														if (validator.isMobilePhone(mobile, "en-IN")) {
															return true;
														} else {
															return "Is not a valid Tax Payer Contact number.";
														}
													}
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Textbox
												tabIndex="6"
												id={"approx_last_year_income"}
												name="approx_last_year_income"
												type="text"
												value={this.state.approx_last_year_income}
												placeholder="Approx Income for FY 22-23 : Rs."
												pattern={"[0-9]*"}
												onChange={(approx_last_year_income) => {
													this.setState({approx_last_year_income});
												}}
												onBlur={e => {
												}}
												onFocus={() => this.checkAuth()}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasApproxLastYearIncomeError: res, validate: false})
												}
												validationOption={{
													type: 'number',
													name: "Approx Income for FY 22-23 : Rs.",
													check: true,
													required: true,
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<Select
												tabIndex="7"
												id={"nature_of_income"}
												name="nature_of_income"
												disabled={false}
												value={this.state.nature_of_income}
												onBlur={(e) => {
												}}
												onChange={(nature_of_income) => {
													this.setState({nature_of_income});
												}}
												onFocus = {( ) => {this.checkAuth()}}
												validate={this.state.validate}
												validationCallback={res =>
													this.setState({hasNatureOfIncomeError: res, validate: false})
												}
												optionList={constant.NATURE_OF_INCOME}
												validationOption={{
													name: 'Nature of Income ',
													check: true,
													required: true,
												}}/>
										</div>
									</div>
									<div className="col-sm-6 col-12 mt-2">
										<div className="mb-4">
											<FormGroup
												label="Previous ITR Filed? :" className="vehicle_type">
												<Radiobox
													tabIndex="8"
													id={"is_previous_itr_filed"}
													name="is_previous_itr_filed"
													disabled={false}
													value={this.state.is_previous_itr_filed}
													onBlur={(e) => {
													}}
													onFocus = {( ) => {this.checkAuth()}}
													onChange={(is_previous_itr_filed) => {
														this.setState({is_previous_itr_filed});
													}}
													validate={this.state.validate}
													validationCallback={res =>
														this.setState({hasIsPreviousItrFiledError: res, validate: false})
													}
													customStyleContainer={{
														display: "flex",
														/*justifyContent: "space-between"*/
													}}
													optionList={[
														{ id: "Yes", name: "Yes" },
														{ id: "No", name: "No" }
													]}
													validationOption={{
														name: 'Previous ITR Filed?',
														check: true,
														required: true,
													}}/>
											</FormGroup>
										</div>
									</div>
								</div>
								{/*<div className="app-bots-checker mt-2">*/}
								{/*<h3>Are you Human ?</h3>*/}
								{/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>*/}
								{/**/}
								{/*</div>*/}
								<Button type="submit" text="Submit" intent="primary" className="my-3"
										loading={this.state.loader}/>
								<div className="clearfix"></div>
							</form>
						</div>

					</Card>
					<div className="clearfix"></div>
				</div>

			</React.Fragment>
        );
    }
}
