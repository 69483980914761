import React from 'react';
// import Form from './include/form';
import {Select, Textbox} from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../../Config';
import {Button, FormGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {Auth, ssoInstance} from "gg-react-utilities";
import {DateInput} from "@blueprintjs/datetime";
import moment from 'moment';
export default class LifeInsuranceInquiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            userId: "",
            name: "",
            email: "",
            mobile: "",
            city: "",
            state: "",
            pincode: "",
            gender: "",
            dateOfBirth: null,
            policyTerm: "",
            calculatePolicy:[{ id: "", name:  "Policy Terms"}],
            tobaccoHabit: "",
            sumAssured: "",
            types: '1',
            dateErrorMsg:null,
            validate: false,
            hasNameError: true,
            hasEmailError: true,
            hasMobileError: true,
            hasCityError: true,
            hasStateError: true,
            hasPincodeError: true,
            hasTypeError: true,
            hasGenderError: true,
            hasPolicyTermError: true,
            hasTobaccoHabitError: true,
            hasSumAssuredError: true,
            loader: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    toggleValidating(validate) {
        this.setState({validate});
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn: res}, () => {
                    if (res === false) {
                        Auth.login(true);
                    }
                })
            });
        }, 1)
    }

    onSubmit(event) {
        event.preventDefault();
        this.toggleValidating(true);
        if(this.state.dateOfBirth===null){
            this.setState({
                dateErrorMsg:"Date of birth cannot be empty"
            });
        }else{
            this.setState({
                dateErrorMsg:null
            });
        }
        if (!this.state.hasNameError &&
            !this.state.hasEmailError &&
            !this.state.hasMobileError &&
            !this.state.hasCityError &&
            !this.state.hasStateError &&
            !this.state.hasGenderError &&
            !this.state.hasPolicyTermError &&
            !this.state.hasTobaccoHabitError &&
            !this.state.hasSumAssuredError &&
            this.state.dateErrorMsg===null &&
            !this.state.hasPincodeError) {
            if(this.state.sumAssured < 500000 || this.state.sumAssured > 1000000000 || this.state.sumAssured % 100000 != 0 ){
                Toaster.create({position: Position.TOP}).show({message:"Please enter sum assured amount in the range between 5 Lakhs and 10 Crore with interval of 1 Lakh.",intent: Intent.DANGER});
                this.setState({sumAssured:""});
            }else {
                if (this.state.isLoggedIn) {
                    this.setState({
                        loader: true
                    });
                    ssoInstance.post(config.API_URL + "insurance", {
                        userId: Auth.user().user_id,
                        userName: Auth.user().username,
                        name: this.state.name,
                        mobile: this.state.mobile,
                        email: this.state.email,
                        city: this.state.city,
                        state: this.state.state,
                        pincode: this.state.pincode,
                        life_gender: this.state.gender,
                        life_dateOfBirth: moment(this.state.dateOfBirth).format("DD/MM/YYYY"),
                        life_policyTerms: this.state.policyTerm,
                        life_tobacciHabit: this.state.tobaccoHabit,
                        life_sumAssured: this.state.sumAssured,
                        insurances_type: this.state.types
                    }).then((response) => {
                        const sources = response.data;
                        if( sources.status === true ){
                            window.location.href = config.APP_URL + "success";

                        } else {
                            Toaster.create({position: Position.TOP_RIGHT}).show({
                                message: response.data.message,
                                intent: Intent.DANGER
                            });
                            this.setState({
                                loader:false
                            });
                        }
                    }).catch((error) => {
                        if(error){
                            const sources = error.response.data;
                            console.log(sources);
                            if(sources.error){
                                Object.keys(sources.error).forEach((key)=>{

                                    error.response.data.error[key].forEach((error)=>{

                                        Toaster.create({
                                            position: Position.TOP,
                                        }).show({message: error, intent:Intent.DANGER});

                                    });

                                });

                            }else{
                                if(sources.status===false){

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: sources.message, intent:Intent.DANGER});

                                }else{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: sources.message, intent:Intent.DANGER});
                                }
                            }
                            // dispatch(actions.paymentCatchResponse(sources));
                        }
                        this.setState({
                            loader:false
                        });
                    });
                } else {
                    console.log(this.state.isLoggedIn, 'isLoggedIn false');
                    this.setState({
                        name: "",
                        email: "",
                        mobile: "",
                        city: "",
                        state: "",
                        pincode: "",
                        gender: "",
                        dateOfBirth: null,
                        policyTerm: "",
                        tobaccoHabit: "",
                        sumAssured: "",
                        types: '1',
                        dateErrorMsg:null,
                        validate: false,
                        hasNameError: true,
                        hasEmailError: true,
                        hasMobileError: true,
                        hasCityError: true,
                        hasStateError: true,
                        hasPincodeError: true,
                        hasTypeError: true,
                        hasGenderError: true,
                        hasPolicyTermError: true,
                        hasTobaccoHabitError: true,
                        hasSumAssuredError: true,
                        loader: false
                    });
                    Auth.login(true)
                }
            }
        } else {
            console.log('Validation Error')
        }
    }

    calculationsPolicy = (data) => {

        let policyLoop = (85 - moment(new Date()).diff(moment(data), 'years'));

        let policyArray =[{ id: "", name:  "Policy Terms"}];

        for( let i = 5; i <= policyLoop; i++ ) {
            policyArray.push({id: i + " (Years)", name: i + " (Years)"} )
        }

        this.setState({
            policyTerm:"",
            dateOfBirth: data,
            calculatePolicy:policyArray
        });

    }

    render() {
        let MaxDate = new Date();
        let MinDate = new Date();
        MaxDate.setFullYear( MaxDate.getFullYear() - 18 );
        MinDate.setFullYear( MinDate.getFullYear() - 85 );

        return (
            <>
                <form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3 pb-5"
                      ref={(el) => this.myFormRef = el}>
                    <div className="row mb-4">
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="1"
                                    id={"Name"}
                                    placeholder="Full Name"
                                    value={this.state.name}
                                    onBlur={e => {
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onChange={(name, e) => {
                                        this.setState({name});
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasNameError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: 'Name',
                                        check: true,
                                        required: true
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="2"
                                    id={"phone"}
                                    name="phone"
                                    type="text"
                                    value={this.state.mobile}
                                    placeholder="Mobile Number"
                                    // onFocus={() => this.checkAuth()}
                                    onChange={(mobile) => {
                                        this.setState({mobile});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasMobileError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Mobile Number",
                                        check: true,
                                        required: true,
                                        customFunc: mobile => {
                                            if (validator.isMobilePhone(mobile, "en-IN")) {
                                                return true;
                                            } else {
                                                return "Is not a valid mobile number.";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="3"
                                    id={"email"}
                                    name="email"
                                    type="text"
                                    value={this.state.email}
                                    // onFocus={() => this.checkAuth()}
                                    placeholder="Email ID"
                                    onChange={(email) => {
                                        this.setState({email});
                                    }}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasEmailError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Email ID",
                                        check: true,
                                        required: true,
                                        customFunc: email => {
                                            if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                                return true;
                                            } else {
                                                return "Is not a valid email address";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="4"
                                    id={"city"}
                                    name="city"
                                    type="text"
                                    value={this.state.city}
                                    placeholder="City"
                                    onChange={(city) => {
                                        this.setState({city});
                                    }}
                                    // onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasCityError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "City",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="5"
                                    type="text"
                                    value={this.state.state}
                                    placeholder="State"
                                    onChange={(state) => {
                                        this.setState({state});
                                    }}
                                    // onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasStateError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "State",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="6"
                                    id={"pincode"}
                                    name="pincode"
                                    type="text"
                                    value={this.state.pincode}
                                    placeholder="Pincode"
                                    onChange={(pincode) => {
                                        this.setState({pincode});
                                    }}
                                    onBlur={e => {
                                    }}
                                    // onFocus={() => this.checkAuth()}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasPincodeError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Pincode",
                                        check: true,
                                        required: true,
                                        customFunc: pincode => {
                                            if (validator.isPostalCode(pincode, "IN")) {
                                                return true;
                                            } else {
                                                return "Is not a valid pincode.";
                                            }
                                        }
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Select
                                    tabIndex="7"
                                    id="gender"
                                    name="gender"
                                    disabled={false}
                                    value={this.state.gender}
                                    onBlur={e => {
                                    }}
                                    onChange={(gender) => {
                                        this.setState({gender});
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasGenderError: res, validate: false})
                                    }
                                    optionList={
                                        [
                                            { id: "", name:  "Your Gender"},
                                            { id: "Male", name: "Male" },
                                            { id: "Female", name: "Female" }
                                        ]
                                    }
                                    validationOption={{
                                        name: 'Gender',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <FormGroup
                                    helperText={this.state.dateErrorMsg}
                                    label="">
                                        <DateInput
                                            tabIndex="8"
                                            id={"dateOfBirth"}
                                            name={"dateOfBirth"}
                                            formatDate={date => date.toLocaleString('es-ES', {year: "numeric", month: "2-digit", day: "numeric"})}
                                            parseDate={str => new Date(str)}
                                            placeholder={"Date Of Birth"}
                                            value={this.state.dateOfBirth}
                                            validate={this.state.validate}
                                            className={"PurchaseInputDate"}
                                            minDate={MinDate}
                                            maxDate={MaxDate}
                                            onBlur={e => {
                                            }}
                                            validationCallback={res =>
                                                this.setState({hasDateOfBirthError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: 'Date Of Birth',
                                                check: true,
                                                required: true,
                                            }}
                                            onChange={(data) => {
                                                this.calculationsPolicy(data);
                                            }}/>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2 setSelectListHight">
                            <div className="mb-4">
                                <Select
                                    tabIndex="9"
                                    id="policyTerm"
                                    name="policyTerm"
                                    disabled={false}
                                    value={this.state.policyTerm}
                                    onChange={(policyTerm) => {
                                        this.setState({policyTerm});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasPolicyTermError: res, validate: false})
                                    }
                                    optionList={this.state.calculatePolicy}
                                    validationOption={{
                                        name: 'policyTerm',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Select
                                    tabIndex="10"
                                    id="tobaccoHabit"
                                    name="tobaccoHabit"
                                    disabled={false}
                                    value={this.state.tobaccoHabit}
                                    onChange={(tobaccoHabit) => {
                                        this.setState({tobaccoHabit});
                                    }}
                                    onBlur={e => {
                                    }}
                                    onFocus = {( ) => {this.checkAuth()}}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasTobaccoHabitError: res, validate: false})
                                    }
                                    optionList={
                                        [
                                            { id: "", name:  "Tobacco Habit"},
                                            { id: "Yes", name: "Yes" },
                                            { id: "No", name: "No" }
                                        ]
                                    }
                                    validationOption={{
                                        name: 'Tobacco Habit',
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="11"
                                    id={"sumAssured"}
                                    name="sumAssured"
                                    type="text"
                                    value={this.state.sumAssured}
                                    placeholder="Sum Assured Amount"
                                    pattern={"[0-9]*"}
                                    onChange={(sumAssured) => {
                                            this.setState({sumAssured});
                                    }}
                                    onBlur={e => {
                                    }}
                                    // onFocus={() => this.checkAuth()}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasSumAssuredError: res, validate: false})
                                    }
                                    validationOption={{
                                        type: 'number',
                                        name: "Sum Assured Amount",
                                        check: true,
                                        required: true,
                                    }}/>
                            </div>
                        </div>
                    </div>


                    {/*<div className="app-bots-checker">*/}
                    {/*<h3>Are you Human ?</h3>*/}
                    {/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>    */}
                    {/*</div>*/}
                    <Button type="submit" className="mt-4" text="Submit" intent="primary"
                            loading={this.state.loader}/>
                </form>
            </>

        );
    }
}
