const constant = {

    /* Constants FOR QR CODE ADVERTISEMENT */
    PLATFORM_LIST : [
        { id:"", name:"Please Select platform.."},
        { id: "All", name: "All" },
        { id: "Web", name: "Web" },
        { id: "Android", name: "Android" },
        { id: "iOS", name: "iOS" }
    ],
    MODULE_LIST : [
        { id:"", name:"Please Select Module.."},
        { id: "Resale", name: "Resale" },
        { id: "Franchise", name: "Franchise" },
        { id: "Bill Pay", name: "Bill Pay" },
        { id: "BSW", name: "BSW" },
        { id: "Travel & Hotel", name: "Travel & Hotel" },
        { id: "GG Vendor", name: "GG Vendor" },
        { id: "Real State", name: "Real State" },
        { id: "News", name: "News" },
        { id: "Food", name: "Food" }
    ],    
    ADVERTISEMENT_TYPE_LIST : [
        { id: "Image", name: "Image" },
        { id: "Video", name: "Video" },
    ],    
    DURATION_LIST : [
        { id:"", name:"Please Select Duration.."},
        { id: "10", name: "10" },
        { id: "20", name: "20" }
    ],    
    EXPIRE_TYPE_LIST : [
        { id:"", name:"Please Select Expire Type.."},
        { id: "Datewise", name: "Datewise" },
        { id: "View Count", name: "View Count" }
    ],
    VISIBILITY_TYPE_LIST : [
        { id:"", name:"Please Select Visibility Type.."},
        { id: "All", name: "All" },
        { id: "Pincode", name: "Pincode" },
        { id: "Area", name:"Area"},
        { id: "City", name: "City" },
        { id: "State", name: "State" },
        { id: "Coordinate", name: "Coordinate" }
    ],

    /* Constants for DIGITAL ADVERTISEMENT */

    CREATIVE_LIST : [
        { id:"", name:"Please Select Creatives.."},
        { id: "Creative Banner", name: "Creative Banner" },
        { id: "Creative Video", name: "Creative Video" },
    ],
    CREATIVE_SIZE_LIST : [
        { id:"", name:"Please Select Creative Size.."},
        { id: "900X30", name: "900X30" },
        { id: "300X300", name: "300X300" },
    ],

    /* Constants for Vehicle Inquiry */

    VEHICLE_TYPE_LIST : [
        /*{ id: "1", name: "Two Wheeler" },*/
        { id: "2", name: "Four Wheeler" },
        { id: "3", name: "Commercial Vehicle" }
    ],    
    FUEL_TYPE_LIST : [
        { id: "", name: "Select fuel type"},
        { id: "1", name: "Petrol" },
        { id: "2", name: "Diesel" }
    ],    
    CASH_TYPE_LIST : [
        { id: "", name: "Cash / Finance"},
        { id: "1", name: "Cash" },
        { id: "2", name: "Finance" }
    ],
        /* Loan Constants */
    Loan_TYPE_LIST : [
        { id: "1", name: "Home " },
        { id: "2", name: "Property" },
        { id: "3", name: "SME" },
        { id: "4", name: "Unsecured Business" },
        { id: "5", name: "Car" },
        { id: "6", name: "Credit-card" },
        { id: "7", name: "Personal " },
        { id: "8", name: "Project" },
    ],


    /* Insurance Constants */

    INSURANCE_TYPE_LIST : [
        { id: "1", name: "Life Insurance" },
        { id: "2", name: "Health Insurance" },
        { id: "3", name: "General Insurance" }
    ],

    INSURANCE_CATEGORY_TYPE_LIST : [
        { id: "", name: "Category"},
        { id: "Bike", name: "Bike" },
        { id: "Car", name: "Car" }
    ],

    HEALTH_SUM_INSURED_LIST : [
        { id: "", name: "Sum Insured"},
        { id: "1.5 Lakh", name: "1.5 Lakh" },
        { id: "2 Lakh", name: "2 Lakh" },
        { id: "3 Lakh", name: "3 Lakh" },
        { id: "4 Lakh", name: "4 Lakh" },
        { id: "5 Lakh", name: "5 Lakh" },
        { id: "6 Lakh", name: "6 Lakh" },
        { id: "7 Lakh", name: "7 Lakh" },
        { id: "7.5 Lakh", name: "7.5 Lakh" },
        { id: "9 Lakh", name: "9 Lakh" },
        { id: "10 Lakh", name: "10 Lakh" },
        { id: "12 Lakh", name: "12 Lakh" },
        { id: "15 Lakh", name: "15 Lakh" },
        { id: "20 Lakh", name: "20 Lakh" },
        { id: "25 Lakh", name: "25 Lakh" },
        { id: "30 Lakh", name: "30 Lakh" },
        { id: "35 Lakh", name: "35 Lakh" },
        { id: "40 Lakh", name: "40 Lakh" },
        { id: "45 Lakh", name: "45 Lakh" },
        { id: "50 Lakh", name: "50 Lakh" },
        { id: "60 Lakh", name: "60 Lakh" },
        { id: "75 Lakh", name: "75 Lakh" }
    ],

    SERVICE_LIST:[
        { id: '', name: 'Please select a service...' },
        { id: "SEO", name: "SEO" },
        { id: "Social Media Marketing", name: "Social Media Marketing" },
        { id: "PPC (Pay per Click)", name: "PPC (Pay per Click)" },
        { id: "Web Design & Development", name: "Web Design & Development" },
        { id: "Mobile Application Development", name: "Mobile Application Development" },
        { id: "Lead Generation", name: "Lead Generation" },
        { id: "Graphic Design", name: "Graphic Design" }
    ],

    NATURE_OF_INCOME : [
        { id: "", name: "Select Nature of Income"},
        { id: "Salary", name: "Salary" },
        { id: "Salary + Other", name: "Salary + Other" },
        { id: "Salary + Capital Gain + Other", name: "Salary + Capital Gain + Other" },
        { id: "Salary + Capital Gain", name: "Salary + Capital Gain" },
        { id: "Buiness Income", name: "Buiness Income" },
        { id: "Business Income + Capital Gain", name: "Business Income + Capital Gain" },
        { id: "Comission Income", name: "Comission Income" },
        { id: "Professional Income", name: "Professional Income" }
    ]

};

export default constant;