import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { LocationOnRounded } from '@material-ui/icons';


export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      lat:0,
      lng:0
    };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then((results) => {
        var val = "";
        var lat = results[0]['geometry']['location'].lat();
        var lng = results[0]['geometry']['location'].lng();
        for (var i = 0; i < results[0]['address_components'].length; i++) {
          var addressType = results[0]['address_components'][i]['types'][0];
          if(addressType==="political"){
              val = val + results[0]['address_components'][i]['long_name']+"-";
          }else if(addressType==="locality"){
              val = val + results[0]['address_components'][i]['long_name']+"-";           
          }else if(addressType==="administrative_area_level_1"){
              val = val + results[0]['address_components'][i]['long_name'];     
          }
        }
        
        this.props.onDataChange(val);
        this.props.onLat(lat);
        this.props.onLng(lng);
      })
      .catch(error => console.error('Error', error));
  };

  handleAddress = address => {
    geocodeByAddress(address)
      .then(() => {
        this.props.onAddress(address);
        this.setState({address:address})
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <React.Fragment>                 
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.props.onType==="address" ? this.handleAddress : this.handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search your location',
                  className: 'bp3-input'
                })}
                />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      >
                      <span><LocationOnRounded className='text-center'/>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </React.Fragment>

    );
  }
}
