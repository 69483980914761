import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Root from './core';
import HeaderFooter from "./core/HeaderFooter";
import {GgUtils} from "gg-react-utilities";

export default class App extends Component{
    componentDidMount() {
        if (process.env.REACT_APP_ENV === 'production') {
            GgUtils.googleAnalyticalScripts(process.env.REACT_APP_GOOGLE_AUTHENTICATE_CODE);
            GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_CODE);
        }
    }

    render() {
        return (
            <Router>
                <div className="inquiry">
                    <HeaderFooter>
                        <Root/>
                    </HeaderFooter>
                </div>
            </Router>
        );
    }
}