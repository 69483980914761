import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

const content = [
    {
        image: 'https://gg-statics.s3.ap-south-1.amazonaws.com/testimonial/IMG_9426.JPG',
        name: 'Gafar Laiya',
        tag: 'Gujarat Bio Organics Pvt. Ltd.',
        description: '"Global Garner has provided us with a comprehensive, quick and well managed digital marketing strategy that has yielded enormous results in terms of inquiry, SEO, Social Media. His team are a pleasure to work with, as well as being quick to respond and adapt to the needs of your brand."',
    },
    {
        image: 'https://gg-statics.s3.ap-south-1.amazonaws.com/testimonial/IMG_9425.JPG',
        name: 'Venket Ayer',
        tag: 'Sahaj Sarjan',
        description: '"Fantastic work! I am just impressed by their service quality and working strategy. I hired them for the development of my clinic’s Website and they have satisfied me to the full by delivering the exceptional solution. They have a great expertise, are dedicated, attentive, talented and care much about the client needs. Highly recommended."',
    },
]
export default class Testimonial extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingData: true,
            data: ['','','','']
        };
    }

    render(){
        return(
            <React.Fragment>
                <div className="col-12 mt-4">
                    <div className="row">
                        <h2>Testimonials</h2>
                    </div>
                </div>

                <Slider
                    Swipe={true}
                    animateIn={'animateIn'}
                    animateOut={'animateOut'}
                >
                    {content.map((article, index) => <div key={index}>
                        <div className="slide mt-5">
                            <div className="slider-bg">
                                <figure>
                                    <img
                                        alt=''
                                        draggable="false"
                                        className='img-fluid rounded-circle'
                                        src={article.image}
                                    />
                                </figure>
                                <h4>{article.name}</h4>
                                <h5>{article.tag}</h5>
                                <p className="mt-4 mt-s-3">{article.description}</p>
                            </div>
                        </div>
                    </div>)}
                </Slider>

            </React.Fragment>
        )
    }
}