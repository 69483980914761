import React from 'react';
import config from '../../../../Config';
import constant from '../../common/constant';
import {Textbox, Select} from 'react-inputs-validation';
import validator from 'validator';
import {FormGroup, Button, Toaster, Position, Intent} from '@blueprintjs/core';
import {DateRangeInput} from '@blueprintjs/datetime';
import 'react-input-range/lib/css/index.css';
import {Auth, ssoInstance} from "gg-react-utilities";
import moment from 'moment';

export default class QRForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoggedIn: false,
            userId:"",
            platform:"",
            module:"",
            title:"",
            type:"Image",
            image:null,
            url:"",
            duration:"",
            expire_type:"",
            startDate:new Date(),
            endDate:null,
            count:"",
            visibility:"",
            amount:"",
            commission:"",
            acp:"",
            partner:"",
            advisor:"",
            validate:false,
            hasPlatformError:true,
            hasModuleError:true,
            hasTitleError:true,
            hasImageTypeError:true,
            hasUrlError:true,
            hasDurationError:true,
            hasExpireTypeError:true,
            hasCountError:true,
            hasVisibilityError:true,
            hasAmountError:true,
            hasCommissionError:true,
            hasAcpError:true,
            hasPartnerError:true,
            hasAdvisorError:true,
            dateErrorMsg:null,
            loader:false,
            filename:""
        }
        this.handleFile = this.handleFile.bind(this);
        this.onSubmit= this.onSubmit.bind(this);
    }


    handleFile(event){
        this.setState({
            image: event.target.files[0]
        },() => {
            this.setState({
                filename:this.state.image.name
            });
        });
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }


    async checkAuth(){
        setTimeout(()=>{
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn:res},()=>{
                    if(this.state.isLoggedIn!==true){
                        Auth.login(true);
                    }
                })
            });
        },1)
    }

    onSubmit(event){
        event.preventDefault();
        let sdate, edate;

        this.toggleValidating(true);
        if(this.state.endDate===null || this.state.startDate===null){
            this.setState({
                dateErrorMsg:"Expire Schedule cannot be empty"
            });
        }else{
            sdate = moment(new Date(this.state.startDate)).format('YYYY-MM-DD');
            edate = moment(new Date(this.state.endDate)).format('YYYY-MM-DD');
            this.setState({
                dateErrorMsg:null
            });
        }
        if( !this.state.hasPlatformError &&
            !this.state.hasModuleError &&
            !this.state.hasTitleError &&
            !this.state.hasImageTypeError &&
            !this.state.hasUrlError &&
            !this.state.hasDurationError &&
            !this.state.hasExpireTypeError &&
            !this.state.hasCountError &&
            !this.state.hasVisibilityError &&
            !this.state.hasAmountError &&
            !this.state.hasCommissionError &&
            !this.state.hasAcpError &&
            !this.state.hasPartnerError &&
            !this.state.hasAdvisorError &&
            this.state.dateErrorMsg===null){
            this.setState({
                loader: true
            });

            if (this.state.isLoggedIn) {
                const formData = new FormData();
                formData.append('userId', Auth.user().user_id)
                formData.append('userName',Auth.user().username)
                formData.append('platform', this.state.platform)
                formData.append('module', this.state.module)
                formData.append('title', this.state.title)
                formData.append('type', this.state.type)
                formData.append('image', this.state.image)
                formData.append('url', this.state.url)
                formData.append('min', this.state.duration)
                formData.append('expire_type', this.state.expire_type)
                formData.append('sdate', sdate)
                formData.append('edate', edate)
                formData.append('count', this.state.count)
                formData.append('visibility', this.state.visibility)
                formData.append('amount', this.state.amount)
                formData.append('commission', this.state.commission)
                formData.append('acp', this.state.acp)
                formData.append('partner', this.state.partner)
                formData.append('advisor', this.state.advisor)
                ssoInstance.post(config.API_URL + "qr-codes", formData)
                    .then((response) => {
                        const sources = response.data;
                        if( sources.status === true ){
                            window.location.href = config.APP_URL + "success";

                        } else {
                            Toaster.create({position: Position.TOP_RIGHT}).show({
                                message: response.data.message,
                                intent: Intent.DANGER
                            });
                            this.setState({
                                loader:false
                            });
                        }
                    }).catch((error) => {
                    if(error){
                        const sources = error.response.data;
                        console.log(sources);
                        if(sources.error){
                            Object.keys(sources.error).forEach((key)=>{

                                error.response.data.error[key].forEach((error)=>{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent:Intent.DANGER});

                                });

                            });

                        }else{
                            if(sources.status===false){

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});

                            }else{

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});
                            }
                        }
                        // dispatch(actions.paymentCatchResponse(sources));
                    }
                    this.setState({
                        loader:false
                    });
                });
            }else{
                this.setState({
                    platform:"",
                    module:"",
                    title:"",
                    type:"Image",
                    image:null,
                    url:"",
                    duration:"",
                    expire_type:"",
                    startDate:new Date(),
                    endDate:null,
                    count:"",
                    visibility:"",
                    amount:"",
                    commission:"",
                    acp:"",
                    partner:"",
                    advisor:"",
                    validate:false,
                    hasPlatformError:true,
                    hasModuleError:true,
                    hasTitleError:true,
                    hasImageTypeError:true,
                    hasUrlError:true,
                    hasDurationError:true,
                    hasExpireTypeError:true,
                    hasCountError:true,
                    hasVisibilityError:true,
                    hasAmountError:true,
                    hasCommissionError:true,
                    hasAcpError:true,
                    hasPartnerError:true,
                    hasAdvisorError:true,
                    dateErrorMsg:null,
                    loader:false,
                    filename:""
                });
                Auth.login(true);
            }
        }else{

        }

    }
    render(){
        var currentDate = moment(new Date());
        var futureMonth = moment(currentDate).add(6, 'M');
        let adTypeFile;
        if(this.state.type==="Image"){
            adTypeFile =    <div className="col-sm-6 col-12 mt-2">
                                <div className="mb-4">
                                    <label className="bp3-file-input bp3-fill">
                                        <input type="file" accept="image/*" onChange={this.handleFile} />
                                        <span className="bp3-file-upload-input">{this.state.filename==="" ? "Choose Image File" : this.state.filename}</span>
                                    </label>
                                </div>
                            </div>
        }else if(this.state.type==="Video"){
            adTypeFile=     <div className="col-sm-6 col-12 mt-2">
                                <div className="mb-4">
                                    <label className="bp3-file-input bp3-fill">
                                        <input type="file" accept="video/*" onChange={this.handleFile} />
                                        <span className="bp3-file-upload-input">{this.state.filename==="" ? "Choose Video File" : this.state.filename}</span>
                                    </label>
                                </div>
                            </div>
        }
        return(
            <React.Fragment>

                        <form onSubmit={this.onSubmit} className="form-horizontal px-3" encType="multipart/form-data">
                            <h5 className="AdType">Create Advertisement</h5>
                            <div className="row">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Select
                                            tabIndex="1"
                                            value={this.state.platform}
                                            onChange={(platform) => {
                                                this.setState({platform});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({
                                                    hasPlatformError: res,
                                                    validate: false
                                                })}
                                            optionList={constant.PLATFORM_LIST}
                                            validationOption={{
                                                name: "Platform",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Select
                                            tabIndex="2"
                                            value={this.state.module}
                                            onChange={(module) => {
                                                this.setState({module});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({
                                                    hasModuleError: res,
                                                    validate: false
                                                })}
                                            optionList={constant.MODULE_LIST}
                                            validationOption={{
                                                name: "Module",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 pt-2">
                                <Textbox
                                    tabIndex="3"
                                    type="text"
                                    id="title"
                                    value={this.state.title}
                                    placeholder="Title"
                                    onChange={(title) => {
                                        this.setState({title});
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasTitleError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Title",
                                        check: true,
                                        required: true
                                    }}/>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Select
                                            tabIndex="4"
                                            value={this.state.type}
                                            onChange={(type) => {
                                                this.setState({type});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({
                                                    hasImageTypeError: res,
                                                    validate: false
                                                })}
                                            optionList={constant.ADVERTISEMENT_TYPE_LIST}
                                            validationOption={{
                                                name: "Advertisement Type",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                                {adTypeFile}
                            </div>

                            <div className="mb-4 mt-2">
                                <Textbox
                                    tabIndex="4"
                                    id={"url"}
                                    name="url"
                                    type="text"
                                    value={this.state.url}
                                    placeholder="Redirect URL"
                                    onChange={(url) => {
                                        this.setState({url});
                                    }}
                                    onFocus={() => this.checkAuth()}
                                    onBlur={e => {
                                    }}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasUrlError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Redirect URL",
                                        check: true,
                                        required: true,
                                        customFunc: url => {
                                            if (validator.isURL(url, {
                                                protocols: ['http', 'https'],
                                                require_tld: true,
                                                require_protocol: false,
                                                require_host: true,
                                                require_valid_protocol: true,
                                                allow_underscores: false,
                                                host_whitelist: false,
                                                host_blacklist: false,
                                                allow_trailing_dot: false,
                                                allow_protocol_relative_urls: false,
                                                disallow_auth: false
                                            })) {
                                                return true;
                                            } else {
                                                return "Is not a valid Landing Page URL.";
                                            }
                                        }
                                    }}/>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Select
                                            tabIndex="5"
                                            value={this.state.duration}
                                            onChange={(duration) => {
                                                this.setState({duration});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({
                                                    hasDurationError: res,
                                                    validate: false
                                                })}
                                            optionList={constant.DURATION_LIST}
                                            validationOption={{
                                                name: "Min Play Duration",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Select
                                            tabIndex="6"
                                            value={this.state.expire_type}
                                            onChange={(expire_type) => {
                                                this.setState({expire_type});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({
                                                    hasExpireTypeError: res,
                                                    validate: false
                                                })}
                                            optionList={constant.EXPIRE_TYPE_LIST}
                                            validationOption={{
                                                name: "AD Expire Type",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <FormGroup
                                helperText={this.state.dateErrorMsg}
                                label="Expire Schedule"
                                labelFor="text-input">
                                <DateRangeInput
                                    formatDate={date => date.toLocaleDateString()}
                                    shortcuts={false}
                                    parseDate={str => new Date(str)}
                                    minDate={new Date()}
                                    maxDate={futureMonth['_d']}
                                    onFocus={() => this.checkAuth()}
                                    fill={true}
                                    value={[this.state.startDate, this.state.endDate]}
                                    onChange={(range) => {
                                        this.setState({startDate: range[0], endDate: range[1]});
                                    }}/>
                            </FormGroup>
                            <div className="row mt-4">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="7"
                                            type="number"
                                            value={this.state.count}
                                            placeholder="Expire View Count"
                                            onChange={(count) => {
                                                this.setState({count});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasCountError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Expire View Count",
                                                check: true,
                                                required: true,
                                                type: 'number',
                                                min: 0
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Select
                                            tabIndex="8"
                                            value={this.state.visibility}
                                            onChange={(visibility) => {
                                                this.setState({visibility});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({
                                                    hasVisibilityError: res,
                                                    validate: false
                                                })}
                                            optionList={constant.VISIBILITY_TYPE_LIST}
                                            validationOption={{
                                                name: "Visibility Type",
                                                check: true,
                                                required: true,
                                            }}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="9"
                                            type="text"
                                            value={this.state.amount}
                                            placeholder="Amount"
                                            onChange={(amount) => {
                                                this.setState({amount});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasAmountError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Amount",
                                                check: true,
                                                required: true
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="10"
                                            type="text"
                                            value={this.state.commission}
                                            placeholder="Commission"
                                            onChange={(commission) => {
                                                this.setState({commission});
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            onBlur={e => {
                                            }}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasCommissionError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Commission",
                                                check: true,
                                                required: true
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="11"
                                            type="text"
                                            value={this.state.acp}
                                            placeholder="ACP ID"
                                            onChange={(acp) => {
                                                this.setState({acp});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasAcpError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "ACP ID",
                                                check: true,
                                                required: true
                                            }}/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="12"
                                            type="text"
                                            value={this.state.partner}
                                            placeholder="Partner ID"
                                            onChange={(partner) => {
                                                this.setState({partner});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasPartnerError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Partner ID",
                                                check: true,
                                                required: true
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mt-2">
                                    <div className="mb-4">
                                        <Textbox
                                            tabIndex="13"
                                            type="text"
                                            id="advisor"
                                            value={this.state.advisor}
                                            placeholder="Advisor ID"
                                            onChange={(advisor) => {
                                                this.setState({advisor});
                                            }}
                                            onBlur={e => {
                                            }}
                                            onFocus={() => this.checkAuth()}
                                            validate={this.state.validate}
                                            validationCallback={res =>
                                                this.setState({hasAdvisorError: res, validate: false})
                                            }
                                            validationOption={{
                                                name: "Advisor ID",
                                                check: true,
                                                required: true
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="app-bots-checker">*/}
                            {/*<h3>Are you Human ?</h3>*/}
                            {/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>*/}
                            {/*</div>*/}
                            <Button type="submit" text="Submit" intent="primary" loading={this.state.loader}/>
                        </form>

            </React.Fragment>
        );
    }
}
