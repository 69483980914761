import React from 'react';
import {Radiobox} from 'react-inputs-validation';
import config from '../../../../Config';
import constant from '../../common/constant';
import {Card, Elevation, FormGroup, Toaster, Position, Intent} from '@blueprintjs/core';
import {Auth,ssoInstance} from "gg-react-utilities";
import HomeLoanInquiry from "./HomeLoanInquiry";
import LoanAgainst from "./LoanAgainst";
import SmeLoan from "./SmeLoan";
import UnsecuredBusinessLoan from "./UnsecuredBusinessLoan";
import CarLoan from "./CarLoan";
import CreditCard from "./CreditCardLoan"
import PersonalLoan from "./PersonalLoan"
import ProjectLoan from "./ProjectLoan"
export default class Form extends React.Component{
    constructor(props){
        super(props);
        this.state={
			isLoggedIn: false,
        	userId:"",
            name:"",
            email:"",
            mobile:"",
            city:"",
            state:"",
            pincode:"",
            types:'1',
            validate:false,
            hasNameError:true,
			hasEmailError:true,
			hasMobileError:true,
			hasCityError:true,
			hasStateError:true,
			hasPincodeError:true,
            hasTypeError:true,
            loader:false
        }
        this.onSubmit= this.onSubmit.bind(this);
    }
    toggleValidating(validate) {
        this.setState({ validate });
    }

	async checkAuth(){
		setTimeout(()=>{
			Auth.isLoggedInAsync().then((res) => {
				this.setState({isLoggedIn:res},()=>{
					if(res===false){
						Auth.login(true);
					}
				})
			});
		},1)
	}

    onSubmit(event){
        event.preventDefault();
        this.toggleValidating(true);
        if(!this.state.hasNameError &&
			!this.state.hasEmailError &&
			!this.state.hasMobileError &&
			!this.state.hasCityError &&
			!this.state.hasStateError &&
			!this.state.hasPincodeError &&
			!this.state.hasTypeError){
			this.setState({
				loader:true
			});
			if (this.state.isLoggedIn){
				ssoInstance.post(config.API_URL+"insurance", {
					userId:Auth.user().user_id,
          			userName:Auth.user().username,
					name: this.state.name,
					mobile: this.state.mobile,
					email: this.state.email,
					city: this.state.city,
					state: this.state.state,
					pincode: this.state.pincode,
					insurances_type: this.state.types
				}).then((response) => {
					if(response.data.subject==="Error"){
						Toaster.create({position: Position.TOP_RIGHT}).show({message:response.data.message,intent: Intent.DANGER});
					}else if(response.data.subject==="Success"){
						window.location.href=config.APP_URL+"success";
					}
				});
			} else {
				this.setState({
					name:"",
					email:"",
					mobile:"",
					city:"",
					state:"",
					pincode:"",
					types:"",
					validate:false,
					hasNameError:true,
					hasEmailError:true,
					hasMobileError:true,
					hasCityError:true,
					hasStateError:true,
					hasPincodeError:true,
					hasTypeError:true,
					loader:false
				});
				Auth.login(true)
			}
        }else{

        }
    }

	renderCurrentForm =()=>{
    	let forms=[
    		<HomeLoanInquiry />,
    		<LoanAgainst />,
    		<SmeLoan />,
    		<UnsecuredBusinessLoan />,
    		<CarLoan />,
			<CreditCard/>,
			<PersonalLoan/>,
			<ProjectLoan/>
		]
		return forms[+this.state.types-1]
	};

    render(){

    	let curForm=this.renderCurrentForm();
        return(
			<React.Fragment>
						<div className="container app-form-card mb-5">
							<Card interactive={true} elevation={Elevation.THREE}
								  className="pt-4 pb-5 p-s-0 p-sm-4 pb-s-5 pb-sm-5 justify-content-center d-flex">
								<div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 py-4 px-2">
									<FormGroup
										label="Loan Type:">
										<Radiobox
											tabIndex="7"
											id={"types"}
											name="types"
											disabled={false}
											value={this.state.types}
											onBlur={(e) => {
											}}
											onChange={(types, e) => {
												//this.setState({index:0})
												this.setState({types});
											}}
											onFocus={() => this.checkAuth()}
											validate={this.state.validate}
											validationCallback={res =>
												this.setState({hasTypeError: res, validate: false})
											}
											optionList={constant. Loan_TYPE_LIST}
											customStyleContainer={{
												display: 'flex',
												justifyContent: 'space-between'
											}}
											validationOption={{
												name: 'Insurance Types',
												check: true,
												required: true
											}}/>
									</FormGroup>

									{curForm}

								</div>
							</Card>
						</div>

			</React.Fragment>
        );
    }
}
