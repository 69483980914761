const config = {
    APP_URL: process.env.REACT_APP_BASE_URL+'inquiry.globalgarner'+process.env.REACT_APP_Extension+'/',
    // API_URL: 'https://beta-inquiry-api.globalgarner'+process.env.REACT_APP_Extension+'/',
    API_URL : process.env.REACT_APP_BASE_URL+'inquiry-api.globalgarner'+process.env.REACT_APP_Extension+'/api/',
    EMAIL_REGEX : /[^@]+@[^@]+\.[^@]+/
};


export default config;

export const packageConfigure={

}
