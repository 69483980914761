import React from 'react';
import {Route} from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => (

    <Route {...rest} render={(props) => (
        <div className="app-wrapper">
            {/*<Header/>*/}
            <Component {...props} />
            {/*<ScrollTop/>*/}
            {/*<Footer/>*/}
        </div>
    )}/>
);

export default PrivateRoute;
