import React, {Component} from 'react';
import Navbar from '../common/navbar';
import Form from './include/form';


export default class HRInquiry extends Component{
    constructor(props){
        super(props);
        document.title = "Best HR Services | Global Garner ";
    }

    componentDidMount(){

    }

    render(){
        let metaData = document.getElementsByTagName("meta");
        metaData['description'].content = 'Top HR Services – Global Garner is one of the best HR Service provider dealing with Recruitment ,HR Solutions,Overseas Placement Services etc Services in India';
        metaData['keywords'].content = 'Placement Consultant, Payroll outsourcing in Ahmadabad, Hr Service in Ahmadabad, Training and Development, HR audit, HR Services via Global Garner';
        return(
            <React.Fragment>
                <div className="header-below px-3">
                    <div className="header-below-content">
                        <h1 className="heading">HR Services</h1>
                        <div className="heading-desc">
                            <p>Want HR consultancy but worried about financials? Forget it! Now get all
                                HR solutions under one roof. Get diversified range of professional and
                                corporate consultancy with never before offer of 100% cashback/ reward.</p>
                        </div>
                    </div>
                </div>
                <Navbar />
                <Form {...this.props} />
            </React.Fragment>
        );
    }
}
