import React from 'react';
import Navbar from '../common/navbar';
import Form from './include/form';

export default class InsuranceInquiry extends React.Component{
    constructor(props){
        super(props);
        document.title = "Loan Inquiry | Global Garner";
    }
    
    componentDidMount() {

    }

    render(){
        return(
            <React.Fragment>
                <div className="header-below px-3">
                    <div className="header-below-content">
                        <h1 className="heading">Loan Inquiry</h1>
                        </div>
                </div>
                <Navbar />
                <Form />
            </React.Fragment>
        );
    }
}
