import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";
import {NavLink} from 'react-router-dom';
// import Form from './include/form';

export default class Navbar extends Component{
    render(){
        return( 
            <React.Fragment>
                <div className="container app-card">
                    <Card interactive={true}>
                        <div className="app-navbar mb-s-1">
                            <ul className="navbar">
                                <li><NavLink to="/" activeClassName="active" exact>Vehicle</NavLink></li>
                                <li><NavLink to="/insurance-inquiry" activeClassName="active">Insurance</NavLink></li>
                                <li><NavLink to="/hr-inquiry" activeClassName="active">HR Services</NavLink></li>
                                <li><NavLink to="/itr-inquiry"  activeClassName="active">Free ITR Filing</NavLink></li>
                                {/*<li><NavLink to="/advertisement"  activeClassName="active">Advertisement</NavLink></li>
                                <li><NavLink to="/cloud"  activeClassName="active">Cloud Storage</NavLink></li>*/}
                                {/*<li><NavLink to="/loan-inquiry"  activeClassName="active">Loan</NavLink></li>*/}
                            </ul>
                        </div>
                    </Card>
                </div>
            </React.Fragment>            
        );
    }
}