import React from 'react';
import {Radiobox} from 'react-inputs-validation';
import {Card, Elevation, FormGroup, Dialog, Classes, Button} from '@blueprintjs/core';
import DigitalForm from './ADForm';
import QRForm from './QRform';

const AD_TYPE_LIST = [
    { id:"0", name:"Digital Advertisement"},
    { id:"1", name:"QR Code Advertisement"}
]

export default class AdType extends React.Component{
    constructor(props){
        super(props);
        this.state={
            type:"0",
            isOpen: true
        }
        this.handleClose = this.handleClose.bind(this);
    }    
    handleClose(){
        this.setState({
            isOpen:false
        });
    }

    render(){
        let form, dialog;        
        if(this.state.type==="0"){
            form = <DigitalForm />;                
            dialog = <Dialog                
                        isOpen = {this.state.isOpen}
                        onClose = {this.handleClose}
                        title="Build your Reach Locally">
                        <div className={Classes.DIALOG_BODY}>
                            <p>Target local prospects when they are physically near your business or your competitors.</p>
                            <p>Select a specific range in the map. Once the prospect is within the parameters
                                that you’ve set, a they will see your ad while they’re using their mobile 
                                devices to surf the web, playing a game or simply sitting at their desktop.</p>
                        </div> 
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>                           
                                <Button onClick={this.handleClose} className="bp3-secondary">Close</Button>                           
                            </div>
                        </div>                       
                    </Dialog> ;
        }else if(this.state.type==="1"){
            form = <QRForm /> ;
            dialog = <Dialog                
                        isOpen = {this.state.isOpen}
                        onClose = {this.handleClose}
                        title="Build your brands with the help of Advertisement">
                        <div className={Classes.DIALOG_BODY}>
                            <p>Dear Users,</p>
                            <p>QR Ads is a feature where you can show your video or image advertisement 
                                under our QR code scanning. With this advertisement, you can promote or
                                increase your business with good revenue. Input your details and our 
                                support team will contact you sortly.</p>
                            <p>Thank you!</p>
                        </div> 
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>                           
                                <Button onClick={this.handleClose} className="bp3-secondary">Close</Button>                           
                            </div>
                        </div>                       
                    </Dialog> ;
        }
        return(
            <div className="container app-form-card mb-5">
                <div className="app-form-container">
                    <Card interactive={true} elevation={Elevation.THREE} className="pt-4 pb-5 px-5 p-s-0 p-sm-4 pb-s-5 pb-sm-5 justify-content-center d-flex">
                        <div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 py-4 px-2">
							<div className="pt-4 px-4">
								<h5 className="AdType">Advertisement Types</h5>
								<FormGroup>
									<Radiobox 
										tabIndex="1"
										id={"type"}
										name="type"
										disabled={false}
										value={this.state.type}
										onBlur={(e) => {}}
										onChange={(type, e) =>{
											this.setState({ type, isOpen: true }); 
										}}
										validate={this.state.validate}
										validationCallback={res =>
											this.setState({ hasTypeError: res, validate: false })
										}                                     
										optionList={AD_TYPE_LIST}
										customStyleContainer={{
											
										}} 
										validationOption={{
											name: 'Advertisement',
											check: true, 
											required: true
										}}/>
								</FormGroup>
							</div>
                        {form}
                        {dialog}
						</div>
                    </Card>
                </div>
            </div> 
        );
    }
}

