import React from 'react';
import {Textbox} from 'react-inputs-validation';
import validator from "validator";
import config from '../../../../Config';
import {Card, Elevation, FormGroup, Checkbox, Button, Position, Toaster, Intent} from '@blueprintjs/core';
import {Auth, ssoInstance} from "gg-react-utilities";

export default class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: null,
            userId: "",
            name: "",
            mobile: "",
            email: "",
            city: "",
            state: "",
            pincode: "",
            firstCheckbox: "Payroll Outsourcing",
            isCheckedFirst: false,
            secondCheckbox: "HR Document Preparation",
            isCheckedSecond: false,
            thirdCheckbox: "Training and Development",
            isCheckedThird: false,
            fourthCheckbox: "HR Audit",
            isCheckedFourth: false,
            fifthCheckbox: "Recruitment Services (Permanent / Contractual )",
            isCheckedFifth: false,
            checkboxErrorMsg: false,
            services: [],
            validate: false,
            hasNameError: true,
            hasEmailError: true,
            hasMobileError: true,
            hasCityError: true,
            hasStateError: true,
            hasPincodeError: true,
            loader: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({isLoggedIn: res}, () => {
                    if (this.state.isLoggedIn !== true) {
                        Auth.login(true);
                    }
                })
            });
        }, 1)
    }

    toggleValidating(validate) {
        this.setState({validate});
    }

    onSubmit(event) {
        event.preventDefault();
        this.toggleValidating(true);
        if (!this.state.hasNameError &&
            !this.state.hasEmailError &&
            !this.state.hasMobileError &&
            !this.state.hasCityError &&
            !this.state.hasStateError &&
            !this.state.hasPincodeError &&
            (this.state.isCheckedFirst === true ||
                this.state.isCheckedSecond === true ||
                this.state.isCheckedThird === true ||
                this.state.isCheckedFourth === true ||
                this.state.isCheckedFifth === true)) {
            if (this.state.isCheckedFirst === true) {
                this.setState({
                    services: this.state.services.push(this.state.firstCheckbox)
                });
            }
            if (this.state.isCheckedSecond === true) {
                this.setState({
                    services: this.state.services.push(this.state.secondCheckbox)
                });
            }
            if (this.state.isCheckedThird === true) {
                this.setState({
                    services: this.state.services.push(this.state.thirdCheckbox)
                });
            }
            if (this.state.isCheckedFourth === true) {
                this.setState({
                    services: this.state.services.push(this.state.fourthCheckbox)
                });
            }
            if (this.state.isCheckedFifth === true) {
                this.setState({
                    services: this.state.services.push(this.state.fifthCheckbox)
                });
            }

            this.setState({
                checkboxErrorMsg: false
            });
            if (!this.state.errors) {
                this.setState({
                    checkboxErrorMsg: false,
                    loader: true
                });

                if (this.state.isLoggedIn) {
                    ssoInstance.post(config.API_URL + "hr", {
                        userId: Auth.user().user_id,
                        name: this.state.name,
                        userName: Auth.user().username,
                        mobile: this.state.mobile,
                        email: this.state.email,
                        city: this.state.city,
                        state: this.state.state,
                        pincode: this.state.pincode,
                        services: this.state.services
                    }).then((response) => {
                        const sources = response.data;
                        if( sources.status === true ){
                            window.location.href = config.APP_URL + "success";

                        } else {
                            Toaster.create({position: Position.TOP_RIGHT}).show({
                                message: response.data.message,
                                intent: Intent.DANGER
                            });
                            this.setState({
                                loader:false
                            });
                        }
                    }).catch((error) => {
                        if(error){
                            const sources = error.response.data;
                            console.log(sources);
                            if(sources.error){
                                Object.keys(sources.error).forEach((key)=>{

                                    error.response.data.error[key].forEach((error)=>{

                                        Toaster.create({
                                            position: Position.TOP,
                                        }).show({message: error, intent:Intent.DANGER});

                                    });

                                });

                            }else{
                                if(sources.status===false){

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: sources.message, intent:Intent.DANGER});

                                }else{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: sources.message, intent:Intent.DANGER});
                                }
                            }
                            // dispatch(actions.paymentCatchResponse(sources));
                        }
                        this.setState({
                            loader:false
                        });
                    });
                } else {
                    this.setState({
                        userId: "",
                        name: "",
                        mobile: "",
                        email: "",
                        city: "",
                        state: "",
                        pincode: "",
                        firstCheckbox: "Payroll Outsourcing",
                        isCheckedFirst: false,
                        secondCheckbox: "HR Document Preparation",
                        isCheckedSecond: false,
                        thirdCheckbox: "Training and Development",
                        isCheckedThird: false,
                        fourthCheckbox: "HR Audit",
                        isCheckedFourth: false,
                        fifthCheckbox: "Recruitment Services (Permanent / Contractual )",
                        isCheckedFifth: false,
                        checkboxErrorMsg: false,
                        services: [],
                        validate: false,
                        hasNameError: true,
                        hasEmailError: true,
                        hasMobileError: true,
                        hasCityError: true,
                        hasStateError: true,
                        hasPincodeError: true,
                        loader: false
                    });
                    Auth.login(true)
                }
            } else {
                this.setState({
                    checkboxErrorMsg: false
                });
            }
        } else {
            this.setState({
                checkboxErrorMsg: true
            });
        }
    }

    render() {
        return (
            <React.Fragment>

						<div className="container app-form-card mb-5">
							<Card interactive={true} elevation={Elevation.THREE}
								  className="pt-4 pb-5 px-5 p-s-0 p-sm-4 pb-s-5 pb-sm-5 justify-content-center d-flex">
								<div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 py-4 px-2">
									<form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3 pb-5"
										  ref={(el) => this.myFormRef = el}>
										<div className="row mb-4">
											<div className="col-sm-6 col-12 mt-2">
												<div className="mb-4">
													<Textbox
														tabIndex="1"
														id={"Name"}
														placeholder="Full Name"
														value={this.state.name}
														onBlur={e => {
														}}
														onChange={(name, e) => {
															this.setState({name});
														}}
														onFocus={() => this.checkAuth()}
														validate={this.state.validate}
														validationCallback={res =>
															this.setState({hasNameError: res, validate: false})
														}
														validationOption={{
															name: 'Name',
															check: true,
															required: true
														}}/>
												</div>
											</div>
											<div className="col-sm-6 col-12 mt-2">
												<div className="mb-4">
													<Textbox
														tabIndex="1"
														id={"phone"}
														name="phone"
														type="text"
														value={this.state.mobile}
														placeholder="Mobile Number"
														onChange={(mobile) => {
															this.setState({mobile});
														}}
														onFocus={() => this.checkAuth()}
														onBlur={e => {
														}}
														validate={this.state.validate}
														validationCallback={res =>
															this.setState({hasMobileError: res, validate: false})
														}
														validationOption={{
															name: "Mobile Number",
															check: true,
															required: true,
															customFunc: mobile => {
																if (validator.isMobilePhone(mobile, "en-IN")) {
																	return true;
																} else {
																	return "Is not a valid mobile number.";
																}
															}
														}}/>
												</div>
											</div>

                                    <div className="col-sm-6 col-12 mt-2">
                                        <div className="mb-4">
                                            <Textbox
                                                tabIndex="3"
                                                id={"email"}
                                                name="email"
                                                type="text"
                                                value={this.state.email}
                                                placeholder="Email ID"
                                                onChange={(email) => {
                                                    this.setState({email});
                                                }}
                                                onFocus={() => this.checkAuth()}
                                                onBlur={e => {
                                                }}
                                                validate={this.state.validate}
                                                validationCallback={res =>
                                                    this.setState({hasEmailError: res, validate: false})
                                                }
                                                validationOption={{
                                                    name: "Email ID",
                                                    check: true,
                                                    required: true,
                                                    customFunc: email => {
                                                        if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                                            return true;
                                                        } else {
                                                            return "Is not a valid email address";
                                                        }
                                                    }
                                                }}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 mt-2">
                                        <div className="mb-4">
                                            <Textbox
                                                tabIndex="4"
                                                id={"city"}
                                                name="city"
                                                type="text"
                                                value={this.state.city}
                                                placeholder="City"
                                                onChange={(city) => {
                                                    this.setState({city});
                                                }}
                                                onFocus={() => this.checkAuth()}
                                                onBlur={e => {
                                                }}
                                                validate={this.state.validate}
                                                validationCallback={res =>
                                                    this.setState({hasCityError: res, validate: false})
                                                }
                                                validationOption={{
                                                    name: "City",
                                                    check: true,
                                                    required: true,
                                                }}/>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12 mt-2">
                                        <div className="mb-4">
                                            <Textbox
                                                tabIndex="5"
                                                type="text"
                                                value={this.state.state}
                                                placeholder="State"
                                                onChange={(state) => {
                                                    this.setState({state});
                                                }}
                                                onFocus={() => this.checkAuth()}
                                                onBlur={e => {
                                                }}
                                                validate={this.state.validate}
                                                validationCallback={res =>
                                                    this.setState({hasStateError: res, validate: false})
                                                }
                                                validationOption={{
                                                    name: "State",
                                                    check: true,
                                                    required: true,
                                                }}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 mt-2">
                                        <div className="mb-4">
                                            <Textbox
                                                tabIndex="6"
                                                id={"pincode"}
                                                name="pincode"
                                                type="text"
                                                value={this.state.pincode}
                                                placeholder="Pincode"
                                                onChange={(pincode) => {
                                                    this.setState({pincode});
                                                }}
                                                onFocus={() => this.checkAuth()}
                                                onBlur={e => {
                                                }}
                                                validate={this.state.validate}
                                                validationCallback={res =>
                                                    this.setState({hasPincodeError: res, validate: false})
                                                }
                                                validationOption={{
                                                    name: "Pincode",
                                                    check: true,
                                                    required: true,
                                                    customFunc: pincode => {
                                                        if (validator.isPostalCode(pincode, "IN")) {
                                                            return true;
                                                        } else {
                                                            return "Is not a valid pincode.";
                                                        }
                                                    }
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <FormGroup
                                    helperText={this.state.checkboxErrorMsg ? "Please check atleast one checkbox." : ""}
                                    label="HR Services and Solutions"
                                    labelFor="text-input">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Checkbox label={this.state.firstCheckbox}
                                                      checked={this.state.isCheckedFirst}
                                                      onChange={() => {
                                                          this.setState({
                                                              isCheckedFirst: !this.state.isCheckedFirst
                                                          });
                                                          this.checkAuth()
                                                      }}/>
                                            <Checkbox label={this.state.secondCheckbox}
                                                      checked={this.state.isCheckedSecond}
                                                      onChange={() => {
                                                          this.setState({
                                                              isCheckedSecond: !this.state.isCheckedSecond
                                                          });
                                                          this.checkAuth()
                                                      }}/>
                                        </div>
                                        <div className="col-sm-6">
                                            <Checkbox label={this.state.thirdCheckbox}
                                                      checked={this.state.isCheckedThird}
                                                      onChange={() => {
                                                          this.setState({
                                                              isCheckedThird: !this.state.isCheckedThird
                                                          });
                                                          this.checkAuth()
                                                      }}/>
                                            <Checkbox label={this.state.fourthCheckbox}
                                                      checked={this.state.isCheckedFourth}
                                                      onChange={() => {
                                                          this.setState({
                                                              isCheckedFourth: !this.state.isCheckedFourth
                                                          });
                                                          this.checkAuth()
                                                      }}/>
                                        </div>
                                        <div className="col-sm-6">
                                            <Checkbox className="mt-0" label={this.state.fifthCheckbox}
                                                      checked={this.state.isCheckedFifth}
                                                      onChange={() => {
                                                          this.setState({
                                                              isCheckedFifth: !this.state.isCheckedFifth
                                                          });
                                                          this.checkAuth()
                                                      }}/>
                                        </div>
                                    </div>
                                </FormGroup>
                                {/*<div className="app-bots-checker mt-4">*/}
                                {/*<h3>Are you Human?</h3>*/}
                                {/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>*/}
                                {/**/}
                                {/*</div>*/}
                                <Button type="Submit" text="Submit" intent="primary" className="mt-4"
                                        loading={this.state.loader}/>
                            </form>
                        </div>
                    </Card>
                </div>

            </React.Fragment>
        );
    }
}
