import React from 'react';
// import Form from './include/form';
import { Select, Textbox } from 'react-inputs-validation';
import validator from 'validator';
import config from '../../../../Config';
import { Button, FormGroup, Intent, Position, Toaster } from "@blueprintjs/core";
import { Auth, ssoInstance } from "gg-react-utilities";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
export default class HomeLoanInquiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            isLoggedIn: false,
            userId: "",
            ITRs_2: "",
            ITRs_3: "",
            turn_over_1: "",
            turn_over_2: "",
            turn_over_3: "",
            gross_profit_1: "",
            gross_profit_2: "",
            gross_profit_3: "",
            bill_pic: null,
            name: "",
            residence_address: "",
            email: "",
            mobile: "",
            city: "",
            state: "",
            pincode: "",
            pancard_no:"",
            gender: "",
            monthly_income: "",
            monthly_current_emi: "",
            property_market_value: "",
            total_earning_family:"",
            employee_type: "",
            dateOfBirth: null,
            marital_status: "",
            types: '1',
            dateErrorMsg: null,
            validate: false,
            hasNameError: true,
            hasAddressError: true,
            hasEmailError: true,
            hasMobileError: true,
            hasCityError: true,
            hasStateError: true,
            hasPincodeError: true,
            hasPanError:true,
            hasTypeError: true,
            hasGenderError: true,
            hasMaritalError: true,
            hasIncomeError: true,
            hasEmiError: true,
            hasPropertyError: true,
            hasEarError:true,
            hasEmpError: true,
            loader: false
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({ isLoggedIn: res }, () => {
                    if (res === false) {
                        Auth.login(true);
                    }
                })
            });
        }, 1)
    }
    handleFile = (event) => {
        console.log("kjkkk::::::::", event.target.files[0])
        var infoArea5 = document.getElementById('file-upload-filename');
        this.setState({
            bill_pic: event.target.files[0]
        });

        infoArea5.textContent = event.target.files[0].name;
    }
    handleFileITRs_2 = (event) => {
        var infoArea1 = document.getElementById('file-upload-filename1');
        this.setState({
            ITRs_2: event.target.files[0]
        });

        infoArea1.textContent = event.target.files[0].name;
    }


    handleFileturn_over_1 = (event) => {
        var turn_over11 = document.getElementById('file-upload-filename3');
        this.setState({
            turn_over_1: event.target.files[0]
        });

        turn_over11.textContent = event.target.files[0].name;
    }

    handleFileturn_over_2 = (event) => {
        var turn_over8 = document.getElementById('file-upload-filename4');
        this.setState({
            turn_over_2: event.target.files[0]
        });

        turn_over8.textContent = event.target.files[0].name;
    }

    handleFileturn_over_3 = (event) => {
        var turn_over6 = document.getElementById('file-upload-filename5');
        this.setState({
            turn_over_3: event.target.files[0]
        });

        turn_over6.textContent = event.target.files[0].name;
    }
    handleFilegross_profit_1 = (event) => {
        var turn_over5 = document.getElementById('file-upload-filename6');
        this.setState({
            gross_profit_1: event.target.files[0]
        });

        turn_over5.textContent = event.target.files[0].name;
    }
    handleFilegross_profit_2 = (event) => {
        var turn_over1 = document.getElementById('file-upload-filename7');
        this.setState({
            gross_profit_2: event.target.files[0]
        });

        turn_over1.textContent = event.target.files[0].name;
    }
    handleFilegross_profit_3 = (event) => {
        var turn_over = document.getElementById('file-upload-filename8');
        this.setState({
            gross_profit_3: event.target.files[0]
        });

        turn_over.textContent = event.target.files[0].name;
    }
    handleFileITRs_3 = (event) => {
        var infoArea3 = document.getElementById('file-upload-filename2');
        this.setState({
            ITRs_3: event.target.files[0]
        });

        infoArea3.textContent = event.target.files[0].name;
    }

    onSubmit(event) {
        event.preventDefault();
        this.toggleValidating(true);
        if (this.state.dateOfBirth === null) {
            this.setState({
                dateErrorMsg: "Date of birth cannot be empty"
            });
        } else {
            this.setState({
                dateErrorMsg: null
            });
        }
        if (!this.state.hasNameError &&
            !this.state.hasEmailError &&
            !this.state.hasMobileError &&
            !this.state.hasCityError &&
            !this.state.hasStateError &&
            !this.state.hasGenderError &&
            !this.state.hasMaritalError &&
            !this.state.hasIncomeError &&
            !this.state.hasEmiError &&
            !this.state.hasPanError&&
            !this.state.hasPropertyError &&
            !this.state.hasEarError&&
            !this.state.hasEmpError &&
            this.state.dateErrorMsg === null &&
            !this.state.hasPincodeError) {

            if (this.state.isLoggedIn) {
                this.setState({
                    loader: true
                });
                 let formData = new FormData();
                formData.append('userId', Auth.user().user_id);
                formData.append('userName', Auth.user().username);
                formData.append(' loan_type', this.state.loan_type);
                formData.append('name', this.state.name);
                formData.append('mobile', this.state.mobile);
                formData.append('residence_address', this.state.residence_address);
                formData.append('email', this.state.email);
                formData.append('city', this.state.city);
                formData.append('state', this.state.state);
                formData.append('pincode', this.state.pincode);
                formData.append('pancard_no', this.state.pancard_no);
                formData.append('gender', this.state.gender);
                formData.append('monthly_income', this.state.monthly_income);
                formData.append('monthly_current_emi', this.state.monthly_current_emi);
                formData.append('property_market_value', this.state.property_market_value);
                formData.append('total_earning_family',this.state.total_earning_family);
                formData.append('employee_type', this.state.employee_type);
                formData.append('date_of_birth', moment(this.state.dateOfBirth).format("YYYY/MM/DD"));
                formData.append('ITRs_1', this.state.bill_pic);
                formData.append('ITRs_2', this.state.ITRs_2);
                formData.append('ITRs_3', this.state.ITRs_3);
                formData.append('turn_over_1', this.state.turn_over_1);
                formData.append('turn_over_2', this.state.turn_over_2);
                formData.append('turn_over_3', this.state.turn_over_3);
                formData.append('gross_profit_1', this.state.gross_profit_1);
                formData.append('gross_profit_2', this.state.gross_profit_2);
                formData.append('gross_profit_3', this.state.gross_profit_3);
                formData.append('marital_status', this.state.marital_status);
            
                ssoInstance.post(config.API_URL + "car-loan", formData)
                    .then((response) => {
                        const sources = response.data;
                        if( sources.status === true ){
                            window.location.href = config.APP_URL + "success";

                            this.setState({
                                name: "",
                                email: "",
                                mobile: "",
                                city: "",
                                state: "",
                                pincode: "",
                                pancard_no:"",
                                gender: "",
                                residence_address:'',
                                monthly_income: "",
                                monthly_current_emi: "",
                                property_market_value: "",
                                total_earning_family:"",
                                employee_type: "",
                                dateOfBirth: null,
                                marital_status: "",
                                types: '1',
                            });

                        } else {
                            Toaster.create({position: Position.TOP_RIGHT}).show({
                                message: response.data.message,
                                intent: Intent.DANGER
                            });
                            this.setState({
                                loader:false
                            });
                        }
                    }).catch((error) => {
                    if(error){
                        const sources = error.response.data;
                        console.log(sources);
                        if(sources.error){
                            Object.keys(sources.error).forEach((key)=>{

                                error.response.data.error[key].forEach((error)=>{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent:Intent.DANGER});

                                });

                            });

                        }else{
                            if(sources.status===false){

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});

                            }else{

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});
                            }
                        }
                        // dispatch(actions.paymentCatchResponse(sources));
                    }
                    this.setState({
                        loader:false
                    });
                });
            } else {
                console.log(this.state.isLoggedIn, 'isLoggedIn false');
                this.setState({
                    name: "",
                    email: "",
                    mobile: "",
                    city: "",
                    state: "",
                    pincode: "",
                    pancard_no:"",
                    gender: "",
                    monthly_income: "",
                    monthly_current_emi: "",
                    property_market_value: "",
                    total_earning_family:"",
                    employee_type: "",
                    dateOfBirth: null,
                    marital_status: "",
                    types: '1',
                    dateErrorMsg: null,
                    validate: false,
                    hasNameError: true,
                    hasAddressError: true,
                    hasEmailError: true,
                    hasMobileError: true,
                    hasCityError: true,
                    hasStateError: true,
                    hasPincodeError: true,
                    hasPanError:true,
                    hasTypeError: true,
                    hasGenderError: true,
                    hasMaritalError: true,
                    hasIncomeError: true,
                    hasEmiError: true,
                    hasPropertyError: true,
                    hasEarError:true,
                    hasEmpError: true,
                    loader: false
                });
                Auth.login(true)
            }

        } else {
            console.log('Validation Error')
        }
    }
    calculationsPolicy = (data) => {

        let policyLoop = (85 - moment(new Date()).diff(moment(data), 'years'));


        this.setState({
            dateOfBirth: data,
        });

    }

    render() {
        let MaxDate = new Date();
        let MinDate = new Date();
        MaxDate.setFullYear(MaxDate.getFullYear() - 18);
        MinDate.setFullYear(MinDate.getFullYear() - 85);

        return (
            <>
            <form onSubmit={this.onSubmit} className="form-horizontal py-2 px-3 pb-5"
                ref={(el) => this.myFormRef = el}>
                <div className="row mb-4">
                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="1"
                                id={"Name"}
                                placeholder="Full Name"
                                value={this.state.name}
                                onBlur={e => {
                                } }
                                onFocus={() => this.checkAuth()}
                                onChange={(name, e) => {
                                    this.setState({ name });
                                } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasNameError: res, validate: false })
                                }
                                validationOption={{
                                    name: 'Name',
                                    check: true,
                                    required: true
                                }} />
                        </div>
                    </div>

                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="2"
                                id={"phone"}
                                name="phone"
                                type="text"
                                value={this.state.mobile}
                                placeholder="Mobile Number"
                                // onFocus={() => this.checkAuth()}
                                onChange={(mobile) => {
                                    this.setState({ mobile });
                                } }
                                onBlur={e => {
                                } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasMobileError: res, validate: false })
                                }
                                validationOption={{
                                    name: "Mobile Number",
                                    check: true,
                                     type: 'number',
                                    required: true,
                                     maxlength:10,                                
                                    number: true,
                                    customFunc: mobile => {
                                        if (validator.isMobilePhone(mobile, "en-IN")) {
                                            return true;
                                        } else {
                                            return "Is not a valid mobile number.";
                                        }
                                    }
                                }} />
                        </div>
                    </div>

                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="3"
                                id={"email"}
                                name="email"
                                type="text"
                                value={this.state.email}
                                // onFocus={() => this.checkAuth()}
                                placeholder="Email ID"
                                onChange={(email) => {
                                    this.setState({ email });
                                } }
                                onBlur={e => {
                                } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasEmailError: res, validate: false })
                                }
                                validationOption={{
                                    name: "Email ID",
                                    check: true,
                                    required: true,
                                    customFunc: email => {
                                        if (config.EMAIL_REGEX.test(String(email).toLowerCase())) {
                                            return true;
                                        } else {
                                            return "Is not a valid email address";
                                        }
                                    }
                                }} />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <FormGroup
                                helperText={this.state.dateErrorMsg}
                                label="">
                                <DateInput
                                    tabIndex="8"
                                    id={"dateOfBirth"}
                                    name={"dateOfBirth"}
                                    formatDate={date => date.toLocaleString('es-ES', { year: "numeric", month: "2-digit", day: "numeric" })}
                                    parseDate={str => new Date(str)}
                                    placeholder={"Date Of Birth"}
                                    value={this.state.dateOfBirth}
                                    validate={this.state.validate}
                                    className={"PurchaseInputDate"}
                                    minDate={MinDate}
                                    maxDate={MaxDate}
                                    onBlur={e => {
                                    } }
                                    validationCallback={res =>
                                        this.setState({ hasDateOfBirthError: res, validate: false })
                                    }
                                    validationOption={{
                                        name: 'Date Of Birth',
                                        check: true,
                                        required: true,
                                    }}
                                    onChange={(data) => {
                                        this.calculationsPolicy(data);
                                    } } />
                            </FormGroup>
                        </div>
                    </div>

                  
                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="4"
                                id={"city"}
                                name="city"
                                type="text"
                                value={this.state.city}
                                placeholder="City"
                                onChange={(city) => {
                                    this.setState({ city });
                                } }
                                // onFocus={() => this.checkAuth()}
                                onBlur={e => {
                                } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasCityError: res, validate: false })
                                }
                                validationOption={{
                                    name: "City",
                                    check: true,
                                    required: true,
                                }} />
                        </div>
                    </div>

                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="5"
                                type="text"
                                value={this.state.state}
                                placeholder="State"
                                onChange={(state) => {
                                    this.setState({ state });
                                } }
                                // onFocus={() => this.checkAuth()}
                                onBlur={e => {
                                } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasStateError: res, validate: false })
                                }
                                validationOption={{
                                    name: "State",
                                    check: true,
                                    required: true,
                                }} />
                        </div>
                    </div>

                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="6"
                                id={"pincode"}
                                name="pincode"
                                type="text"
                                value={this.state.pincode}
                                placeholder="Pincode"
                                onChange={(pincode) => {
                                    this.setState({ pincode });
                                } }
                                onBlur={e => {
                                } }
                                // onFocus={() => this.checkAuth()}
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasPincodeError: res, validate: false })
                                }
                                validationOption={{
                                    name: "Pincode",
                                    check: true,
                                    type: 'number',
                                    required: true,
                                    customFunc: pincode => {
                                        if (validator.isPostalCode(pincode, "IN")) {
                                            return true;
                                        } else {
                                            return "Is not a valid pincode.";
                                        }
                                    }
                                }} />
                        </div>
                    </div>
                               <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <Textbox
                                    tabIndex="6"
                                    id={"pancard_no"}
                                    name="pancard_no"
                                    type="text"
                                    value={this.state.pancard_no}
                                    placeholder="Pancard No"
                                    onChange={(pancard_no) => {
                                        this.setState({pancard_no});
                                    }}
                                    onBlur={e => {
                                    }}
                                    // onFocus={() => this.checkAuth()}
                                    validate={this.state.validate}
                                    validationCallback={res =>
                                        this.setState({hasPanError: res, validate: false})
                                    }
                                    validationOption={{
                                        name: "Pancard No",
                                        check: true,
                                        required: true,
 
                                    }}/>
                            </div>
                        </div>
                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="6"
                                id={"monthly_income"}
                                name="monthly_income"
                                type="text"
                                value={this.state.monthly_income}
                                pattern={"[0-9]*"}
                                placeholder="Monthly Income"
                                onChange={(monthly_income) => {
                                    this.setState({ monthly_income });
                                } }
                                onBlur={e => {
                                } }
                                // onFocus={() => this.checkAuth()}
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasIncomeError: res, validate: false })
                                }

                                validationOption={{
                                    type: 'number',
                                    name: "Monthly Income",
                                    check: true,
                                    required: true,
                                }} />
                        </div>
                    </div>

                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="6"
                                id={"monthly_current_emi"}
                                name="monthly_current_emi"
                                type="text"
                                value={this.state.monthly_current_emi}
                                placeholder="Monthly Current EMI"
                                onChange={(monthly_current_emi) => {
                                    this.setState({ monthly_current_emi });
                                } }
                                onBlur={e => {
                                } }
                                // onFocus={() => this.checkAuth()}
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasEmiError: res, validate: false })
                                }
                                validationOption={{
                                    name: "Monthly Current EMI",
                                    type: 'number',
                                    check: true,
                                    required: true,
                                }} />
                        </div>
                    </div>
                         <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="1"
                                id={"residence_address"}
                                placeholder="Residence Address"
                                value={this.state.residence_address}
                                onBlur={e => {
                                } }
                                onFocus={() => this.checkAuth()}
                                onChange={(residence_address, e) => {
                                    this.setState({ residence_address });
                                } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasAddressError: res, validate: false })
                                }
                                validationOption={{
                                    name: 'Residence Address',
                                    check: true,
                                    required: true
                                }} />
                        </div>
                    </div>
                      <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="6"
                                id={"total_earning_family"}
                                name="total_earning_family"
                                type="text"
                                value={this.state.total_earning_family}
                                placeholder="Total Earning Members Of The Family"
                                onChange={(total_earning_family) => {
                                    this.setState({ total_earning_family });
                                } }
                                onBlur={e => {
                                } }
                                // onFocus={() => this.checkAuth()}
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasEarError: res, validate: false })
                                }
                                validationOption={{
                                    type: 'number',                                  
                                    name: "Total Earning Members Of The Family",
                                    check: true,
                                    required: true,
                                }} />
                        </div>
                    </div>
   
                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Textbox
                                tabIndex="6"
                                id={"property_market_value"}
                                name="property_market_value"
                                type="text"
                                value={this.state.property_market_value}
                                placeholder="Property market value"
                                onChange={(property_market_value) => {
                                    this.setState({ property_market_value });
                                } }
                                onBlur={e => {
                                } }
                                // onFocus={() => this.checkAuth()}
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasPropertyError: res, validate: false })
                                }
                                validationOption={{
                                    name: "Property market value",
                                    check: true,
                                    type: 'number',
                                    required: true,
                                }} />
                        </div>
                    </div>

  
                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Select
                                tabIndex="7"
                                id="gender"
                                name="gender"
                                disabled={false}
                                value={this.state.gender}
                                onBlur={e => {
                                } }
                                onChange={(gender) => {
                                    this.setState({ gender });
                                } }
                                onFocus={() => { this.checkAuth() } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasGenderError: res, validate: false })
                                }
                                optionList={
                                    [
                                        { id: "", name: "Your Gender" },
                                        { id: "male", name: "Male" },
                                        { id: "female", name: "Female" },
                                        { id: "other", name: "Other" }
                                    ]
                                }
                                validationOption={{
                                    name: 'Gender',
                                    check: true,
                                    required: true,
                                }} />
                        </div>
                    </div>



                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Select
                                tabIndex="10"
                                id="marital_status"
                                name="marital_status"
                                disabled={false}
                                value={this.state.marital_status}
                                onChange={(marital_status) => {
                                    this.setState({ marital_status });
                                } }
                                onBlur={e => {
                                } }
                                onFocus={() => { this.checkAuth() } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasMaritalError: res, validate: false })
                                }
                                optionList={
                                    [
                                        { id: "", name: "Marital Status" },
                                        { id: "single", name: "single" },
                                        { id: "married", name: "Married" },
                                        { id: "other", name: "Other" },

                                    ]
                                }
                                validationOption={{
                                    name: 'Marital Status',
                                    check: true,
                                    required: true,
                                }} />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 mt-2">
                        <div className="mb-4">
                            <Select
                                tabIndex="7"
                                id="employee_type"
                                name="employee_type"
                                disabled={false}
                                value={this.state.employee_type}
                                onBlur={e => {
                                } }
                                onChange={(employee_type) => {
                                    this.setState({ employee_type });
                                } }
                                onFocus={() => { this.checkAuth() } }
                                validate={this.state.validate}
                                validationCallback={res =>
                                    this.setState({ hasEmpError: res, validate: false })
                                }
                                optionList={
                                    [
                                        { id: "", name: "Employee Type" },
                                        { id: "self", name: "Self" },
                                        { id: "salaried", name: "Salaried" },
                                    ]
                                }
                                validationOption={{
                                    name: 'Employee Type',
                                    check: true,
                                    required: true,
                                }} />,
                            </div>
                    </div>

              
                    {this.state.employee_type == "self" ?
                        <div className="col-sm-6 col-12 mt-2">
                            <div className="mb-4">
                                <FormGroup>
                                    <label className="bp3-file-input bp3-fill">
                                        <input required type="file" name={"bill_pic"} id="bill_pic" accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFile} />
                                        <span className="bp3-file-upload-input">ITRs 1 File </span>
                                    </label>
                                </FormGroup>
                            </div>
                            <div id="file-upload-filename" className="bold"></div>
                        </div> : ""}  {this.state.employee_type == "self" ?

                            <div className="col-sm-6 col-12 mt-2">
                                <div className="mb-4"> <FormGroup>
                                    <label className="bp3-file-input bp3-fill">
                                        <input required type="file" name={"ITRs_2"} id="ITRs_2" accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFileITRs_2} />
                                        <span className="bp3-file-upload-input">ITRs 2 File</span>
                                    </label>
                                </FormGroup></div>
                                <div id="file-upload-filename1" className="bold"></div>
                            </div> : ""}  {this.state.employee_type == "self" ?

                                <div className="col-sm-6 col-12 mt-2">
                                    <div className="mb-4"> <FormGroup>
                                        <label className="bp3-file-input bp3-fill">
                                            <input type="file" name={"ITRs_3"} required id={"ITRs_3"} accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFileITRs_3} />
                                            <span className="bp3-file-upload-input">ITRs 3 File</span>
                                        </label>
                                    </FormGroup></div>
                                    <div id="file-upload-filename2" className="bold"></div>
                                </div> : ""}  {this.state.employee_type == "self" ?

                                    <div className="col-sm-6 col-12 mt-2">
                                        <div className="mb-4"><FormGroup>
                                            <label className="bp3-file-input bp3-fill">
                                                <input type="file" name={"turn_over_1"} required id={"turn_over_1"} accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFileturn_over_1} />
                                                <span className="bp3-file-upload-input">Turn Over 1 File</span>
                                            </label>
                                        </FormGroup>
                                        </div> <div id="file-upload-filename3" className="bold"></div>
                                    </div> : ""}  {this.state.employee_type == "self" ?

                                        <div className="col-sm-6 col-12 mt-2">
                                            <div className="mb-4"> <FormGroup>
                                                <label className="bp3-file-input bp3-fill">
                                                    <input type="file" name={"turn_over_2"} required id={"turn_over_2"} accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFileturn_over_2} />
                                                    <span className="bp3-file-upload-input">Turn Over 2 File</span>
                                                </label>
                                            </FormGroup></div>
                                            <div id="file-upload-filename4" className="bold"></div>
                                        </div> : ""}  {this.state.employee_type == "self" ?

                                            <div className="col-sm-6 col-12 mt-2">
                                                <div className="mb-4"><FormGroup>
                                                    <label className="bp3-file-input bp3-fill">
                                                        <input type="file" name={"turn_over_3"} required id={"turn_over_3"} accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFileturn_over_3} />
                                                        <span className="bp3-file-upload-input">Turn Over 3 File</span>
                                                    </label>
                                                </FormGroup></div>
                                                <div id="file-upload-filename5" className="bold"></div>
                                            </div> : ""}  {this.state.employee_type == "self" ?

                                                <div className="col-sm-6 col-12 mt-2">
                                                    <div className="mb-4"> <FormGroup>
                                                        <label className="bp3-file-input bp3-fill">
                                                            <input type="file" name={"gross_profit_1"} required id={"gross_profit_1"} accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFilegross_profit_1} />
                                                            <span className="bp3-file-upload-input">Gross Profit 1 File</span>
                                                        </label>
                                                    </FormGroup></div>
                                                    <div id="file-upload-filename6" className="bold"></div>
                                                </div> : ""}  {this.state.employee_type == "self" ?

                                                    <div className="col-sm-6 col-12 mt-2">
                                                        <div className="mb-4"><FormGroup>
                                                            <label className="bp3-file-input bp3-fill">
                                                                <input type="file" name={"gross_profit_2"} required id={"gross_profit_2"} accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFilegross_profit_2} />
                                                                <span className="bp3-file-upload-input">Gross Profit 2 File</span>
                                                            </label>
                                                        </FormGroup></div>
                                                        <div id="file-upload-filename7" className="bold"></div>
                                                    </div> : ""}  {this.state.employee_type == "self" ?
                                                        <div className="col-sm-6 col-12 mt-2">
                                                            <div className="mb-4"> <FormGroup>
                                                                <label className="bp3-file-input bp3-fill">
                                                                    <input type="file" name={"gross_profit_3"} required id={"gross_profit_3"} accept=".jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt" onChange={this.handleFilegross_profit_3} />
                                                                    <span className="bp3-file-upload-input">Gross Profit 3 File</span>
                                                                </label>
                                                            </FormGroup></div>
                                                            <div id="file-upload-filename8" className="bold"></div>
                                                        </div>
                                                        : ""}
                 </div>


                {/*<div className="app-bots-checker">*/}
                {/*<h3>Are you Human ?</h3>*/}
                {/*<p>Sorry to ask but we need to keep spam bots out. Please click below checkbox and wait until it is done.</p>    */}
                {/*</div>*/}
                <Button type="submit" className="mt-4" text="Submit" intent="primary"
                    loading={this.state.loader} />
            </form>
            </>

        );
    }
}
