import React from 'react';
import CloudForm from "./CloudForm.js";
import Navbar from "../common/navbar";

export default class Cloud extends React.Component {
    constructor(props) {
        super(props);
        document.title = "Digital Marketing Certification Course in Ahmadabad | Global Garner";
    }

    render() {
        return (
            <React.Fragment>
                <div className="header-below px-3">
                    <div className="header-below-content">
                        <h1 className="heading">Cloud Inquiry</h1>
                        <div className="heading-desc">
                            <p>BrilCloud lets you securely store any type of files online and access them from any location with an internet connection.
                                Throw your worries and buy through us and get 100% cashback/reward.</p>
                        </div>
                    </div>
                </div>
                <div className="container app-card">
                <Navbar />
                    <CloudForm />
                </div>
            </React.Fragment>
        );
    }
}
