import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/src/styles';
import slider_1 from '../images/slider/slider_1.jpg';
import slider_2 from '../images/slider/slider_2.jpg';
import slider_3 from '../images/slider/slider_3.jpg';
import slider_5 from '../images/slider/slider_5.jpg';

const Sliders = (
    <AwesomeSlider
        cssModule={AwsSliderStyles}
        infinite={true}
        autoplay={true}
    >
        <div data-src={slider_1} alt="" />
        <div data-src={slider_2} alt="" />
        <div data-src={slider_3} alt="" />
        <div data-src={slider_5} alt="" />
    </AwesomeSlider>
);

export default class Slider extends React.Component{
    render(){
        return(
            <React.Fragment>
                {
                    Sliders
                }
            </React.Fragment>
        )
    }
}